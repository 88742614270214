"use client";
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import InterllM from "../Assets/img/Home/Interll-M.png";
import Ai from "../Assets/img/Home/Ai.png";
import Ai1 from "../Assets/img/Home/Ai-1.png";
import Ai2 from "../Assets/img/Home/Ai-2.png";
import AIMembers from "../Assets/img/Home/AI-Members.png";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import globe from "../Assets/img/Bg/globe.png";
import { Link } from "react-router-dom";
function GlobalPostsPnl({setSignupShow}) {
  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);
  return (
    <>
      <Container fluid>
        <Row>
          <Container>
            <Row>
              <Col xl="6" lg="6" md="6" className="scroll-anime left">
                <div className="spacer-50 web-view" />
                <div className="spacer-50 web-view" />
                <div className="spacer-50 web-view" />
                <h2 className="whitecolor">Intell-M® Channel Analysis </h2>
                <p className="whitecolor">
                Access consolidated insights from over 10,000 different data platforms. The Intell-M® is the ultimate technical analysis tool for technical analysis and trade planning.  Experience the convenience of integrated TradingView for seamless visualization and charting. 


                </p>
              </Col>
              <Col xl="6" lg="6" md="6" className="scroll-anime right">
                <Image src={InterllM} alt="Interll M" />
                <div className="spacer-50 web-view" />
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>

      <Container fluid>
        <Row>
          <Container>
            <Row>
              <Col
                xl={{ span: 6, order: 1 }}
                lg={{ span: 6, order: 1 }}
                md={{ span: 6, order: 1 }}
                sm={{ span: 12, order: 2 }}
                xs={{ span: 12, order: 2 }}
                className="scroll-anime left"
              >
                <Image src={Ai} alt="AI" />
                <div className="spacer-50 web-view" />
              </Col>
              <Col 
                xl={{ span: 6, order: 2 }}
                lg={{ span: 6, order: 2 }}
                md={{ span: 6, order: 2 }}
                sm={{ span: 12, order: 1 }}
                xs={{ span: 12, order: 1 }} className="scroll-anime right">
                <div className="spacer-50 web-view" />
                <div className="spacer-50 web-view" />
                <div className="spacer-50 web-view" />
                <h2 className="whitecolor">Rodeum AI - Native AI</h2>
                <p className="whitecolor">
                Rodeum is the infrastructure development layer for the upcoming IntelMarkets blockchain. Rodeum AI will be the gateway to decentralized applications and support the INTL ecosystem with industry-leading development support. The development ecosystem on Rodeum is backed by over $500,000 in grants. 

                </p>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
      <Row>
        <Container>
          <Row>
            <Col
              xl={{ span: 10, offset: 1 }}
              lg={{ span: 10, offset: 1 }}
              className="scroll-anime down"
            >
              <div className="global-post full">
                <div className="txt-pnl text-center">
                  <div className="spacer-40" />
                  <h2 className="whitecolor">
                  Our Core Features 

                  </h2>
                  <div className="spacer-20" />
                  <p className="lightcolor">
                  The IntelMarkets product suite is designed to offer a range of products to retail traders and enterprise developers. Our applications will target AI-driven solutions for critical challenges in key industries and facilitate exceptional market performance for traders. 

                  </p>
                  <div className="spacer-20" />
                  <Link className="reg-btn br-radius arrow-btn">
                    <Image src={circleArrow} alt="Arrow" /> Learn More
                  </Link>
                  <div className="spacer-50 web-view" />
                </div>
              </div>
            </Col>
            <Col xl="12" lg="12" md="12"
            onClick={()=>{
              setSignupShow(true)
            }}
            
            >
              <div className="global-post half">
                <div className="txt-pnl">
                  <div className="post-txt scroll-anime left">
                    <Image src={Ai1} alt="AI-Members" />
                    <div className="text">
                      <h3 className="whitecolor">Autonomous Trading Robots </h3>
                      <p className="lightcolor">
                      Maximize profitability in the market with support from our automated trading robots. Configure metrics like profitability, risk management, and preferred assets, and our robots will handle trading to deliver exceptional performance.

                      </p>
                    </div>
                  </div>
                  <div className="post-txt scroll-anime left">
                    <Image src={Ai2} alt="AI-Members" />
                    <div className="text">
                      <h3 className="whitecolor">
                      DigiFort Wallet 

                      </h3>
                      <p className="lightcolor">
                      The upcoming DigiFort wallet is the first quantum-proof crypto custody solution. This decentralized product was developed after months of research and features industry-leading security modules for hassle-free asset management. 

                      </p>
                    </div>
                  </div>
                </div>
                <div className="img-pnl circle-bg-pnl scroll-anime right">
                  <Image src={AIMembers} alt="AI-Members" />
                </div>
              </div>
            </Col>
            <Col
              xl={{ span: 8, offset: 2 }}
              lg={{ span: 10, offset: 1 }}
              className="scroll-anime down protocol"
            >
              <div className="global-post full">
                <div className="txt-pnl text-center">
                  <div className="spacer-50" />
                  <div className="spacer-50" />
                  <h2 className="whitecolor">
                  Route X21 - The First Quantum Proofing Protocol
                  </h2>
                  <div className="spacer-20" />
                  <p className="lightcolor" 
                  onClick={()=>{
                    setSignupShow(true)
                  }}
                  >
                   The Route X21 initiative addresses the growing ability of quantum computing and its potential threats to blockchain ecosystems. This cutting-edge protocol is focused on implementing next-generation cryptographic techniques across products to prevent security challenges. 

                  </p>
                  <div className="spacer-20" />
                  <Link  to={'/Route2X'} className="reg-btn br-radius arrow-btn">
                    <Image src={circleArrow} alt="Arrow" 
                    
                    
                    /> Learn More
                  </Link>
                </div>
              </div>
            </Col>
            <Col xl="12">
              <div className="globe-pnl scroll-anime up">
                <div
                  className="bg-layer"
                  style={{ backgroundImage: `url(${globe})` }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  );
}
export default GlobalPostsPnl;
