import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Image } from "react-bootstrap";
import chainimg from "../Assets/img/chain-img.png";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import Frequentlyquestion from "../Components/Frequentlyquestion";
function Faqs() {
  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);
  return (
    <>
      {/* Faqs Starts Here */}
      <section className="container-fluid faq-main inner-page">
        <div className="bg-layer" />
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" className="text-center scroll-anime down">
                <div className="border-heading">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1_83)">
                      <path
                        d="M4.32407 4.59084L2.85126 4.70462C2.44144 4.7363 2.07644 4.95109 1.84979 5.29391L0.151236 7.86288C-0.0206556 8.12286 -0.0479816 8.44821 0.0780897 8.73323C0.204194 9.01824 0.463343 9.21686 0.771299 9.26457L2.11983 9.47342C2.43529 7.79933 3.19046 6.12936 4.32407 4.59084ZM7.52626 14.8798L7.73514 16.2284C7.78285 16.5363 7.98147 16.7955 8.26645 16.9216C8.38262 16.9731 8.50829 16.9998 8.6354 16.9999C8.8138 16.9998 8.9882 16.9471 9.13683 16.8484L11.7059 15.1499C12.0487 14.9232 12.2635 14.5582 12.2951 14.1484L12.4089 12.6756C10.8703 13.8092 9.20038 14.5644 7.52626 14.8798ZM7.01908 13.9451C7.0659 13.9451 7.11298 13.9413 7.15996 13.9335C7.86134 13.8162 8.53735 13.6186 9.18222 13.36L3.63966 7.81749C3.38108 8.46232 3.18352 9.13833 3.06625 9.83974C3.02003 10.1162 3.11277 10.3979 3.31095 10.5961L6.4036 13.6888C6.56815 13.8533 6.79025 13.9451 7.01908 13.9451ZM15.6442 7.53709C17.0005 4.91493 17.0508 2.14395 16.9799 0.844389C16.9556 0.399106 16.6006 0.0441006 16.1553 0.0198293C15.9072 0.00644589 15.6587 -0.000186072 15.4102 -5.92504e-05C13.9571 -5.92504e-05 11.6578 0.220043 9.46258 1.35548C7.71804 2.25784 5.43046 4.24473 4.08083 6.85587C4.09677 6.86832 4.11234 6.88153 4.12701 6.89621L10.1035 12.8727C10.1182 12.8874 10.1314 12.9029 10.1438 12.9189C12.755 11.5692 14.7419 9.28164 15.6442 7.53709ZM9.88419 3.59382C10.8551 2.62293 12.4349 2.62283 13.4059 3.59382C13.8762 4.06414 14.1352 4.68951 14.1352 5.35467C14.1352 6.01982 13.8762 6.6452 13.4059 7.11551C12.9205 7.60091 12.2826 7.84365 11.645 7.84372C11.0072 7.84375 10.3697 7.60104 9.88419 7.11551C9.41384 6.6452 9.15479 6.01982 9.15479 5.35467C9.15479 4.68951 9.41384 4.06414 9.88419 3.59382Z"
                        fill="white"
                      ></path>
                      <path
                        d="M10.5888 6.4112C11.1713 6.99374 12.1193 6.99377 12.7018 6.4112C12.984 6.12897 13.1394 5.75378 13.1394 5.35468C13.1394 4.95558 12.984 4.58039 12.7018 4.2982C12.4105 4.00691 12.0279 3.86128 11.6453 3.86128C11.2627 3.86128 10.8801 4.00691 10.5888 4.2982C10.3066 4.58039 10.1511 4.95558 10.1511 5.35468C10.1511 5.75378 10.3066 6.129 10.5888 6.4112ZM0.508176 14.0095C0.635642 14.0095 0.763107 13.9609 0.860325 13.8636L2.48637 12.2376C2.68087 12.0431 2.68087 11.7278 2.48637 11.5333C2.2919 11.3388 1.97654 11.3388 1.78204 11.5333L0.155993 13.1593C-0.0385095 13.3538 -0.0385095 13.6691 0.155993 13.8636C0.20219 13.91 0.257086 13.9467 0.317525 13.9717C0.377964 13.9968 0.442756 14.0096 0.508176 14.0095ZM3.97655 13.0234C3.78208 12.8289 3.46672 12.8289 3.27221 13.0234L0.145999 16.1497C-0.0485035 16.3442 -0.0485035 16.6595 0.145999 16.854C0.24325 16.9512 0.370716 16.9999 0.498182 16.9999C0.625647 16.9999 0.753113 16.9512 0.850331 16.854L3.97651 13.7278C4.17105 13.5333 4.17105 13.2179 3.97655 13.0234ZM4.76236 14.5136L3.13635 16.1397C2.94185 16.3342 2.94185 16.6495 3.13635 16.844C3.2336 16.9413 3.36107 16.9899 3.4885 16.9899C3.61593 16.9899 3.74343 16.9413 3.84065 16.844L5.46669 15.218C5.66119 15.0235 5.66119 14.7081 5.46669 14.5136C5.27222 14.3191 4.95686 14.3191 4.76236 14.5136Z"
                        fill="white"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_1_83">
                        <rect width="17" height="17" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  FAQ
                </div>
                <h1 className="heading-text">
                  Frequently Asked <br />
                  <span>Questions.</span>
                </h1>
              </Col>
              <Col xl="12">
                <div className="frequently-blur-layer">
                  <Frequentlyquestion />
                </div>
              </Col>
            </Row>
          </Container>
          <Container>
            <Row>
              <Col xl="12" lg="12">
                <div className="trade-with-panel">
                  <div className="text-pnl scroll-anime left">
                    <h1 className="light">Trade with IntelMarkets.</h1>
                    <p className="light">
                      Connect your favorite wallet and experience the future of
                      trading with Intel Markets.
                    </p>
                    <div className="btn-list ">
                      <li>
                        <Link className="reg-btn br-radius mb-1 arrow-btn">
                          <Image src={circleArrow} alt="Arrow" />
                          Join Presale
                        </Link>
                      </li>
                      <li>
                        <Link className="reg-btn trans br-radius">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.0799 8.58003V15.42C21.0799 16.54 20.4799 17.58 19.5099 18.15L13.5699 21.58C12.5999 22.14 11.3999 22.14 10.4199 21.58L4.47991 18.15C3.50991 17.59 2.90991 16.55 2.90991 15.42V8.58003C2.90991 7.46003 3.50991 6.41999 4.47991 5.84999L10.4199 2.42C11.3899 1.86 12.5899 1.86 13.5699 2.42L19.5099 5.84999C20.4799 6.41999 21.0799 7.45003 21.0799 8.58003Z"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M11.9999 11C13.2867 11 14.3299 9.95681 14.3299 8.66998C14.3299 7.38316 13.2867 6.34003 11.9999 6.34003C10.7131 6.34003 9.66992 7.38316 9.66992 8.66998C9.66992 9.95681 10.7131 11 11.9999 11Z"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                            <path
                              d="M16 16.66C16 14.86 14.21 13.4 12 13.4C9.79 13.4 8 14.86 8 16.66"
                              stroke="#fff"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </svg>{" "}
                          Join the Waitlist
                        </Link>
                      </li>
                    </div>
                  </div>
                  <div className="img-pnl">
                    <Image src={chainimg} alt="Chain" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Faqs Ends Here */}
    </>
  );
}
export default Faqs;
