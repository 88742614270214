import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Row,
  Container,
  Col,
  Button,
  Modal,
  Form,
  Image,
} from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import check from "../../Assets/img/admin-icons/icon-check.png";
import iconi from "../../Assets/img/admin-icons/icon-i.png";
import iconlogo from "../../Assets/img/admin-icons/icon-logo.png";
import iconusd from "../../Assets/img/admin-icons/icon-tether.png";
import iconutt from "../../Assets/img/Icons/icon-teather.png";
import iconeth from "../../Assets/img/admin-icons/icon-etherium.png";
import iconbnb from "../../Assets/img/admin-icons/icon-bnb.png";
import iconired from "../../Assets/img/Icons/icon-ii.png";
import circleArrow from "../../Assets/img/Icons/icon-arrow-right.png";
import ChatButton from "../../Components/ChatButton";
import UserContest from "../../ContextAPI/UserContest";
import { CircularProgressbar } from "react-circular-progressbar";
import {
  GetSelectedCurrencyPrice,
  GraphTotal,
  UpdateProfileApi,
  WalletaddressChanage,
  WalletaddressRequest,
  getBonusTokenCountWithOutStage,
  getSumOfCurrencies,
  getTokenBonusCount,
  getTotalTokenOfUser,
  getUserDetail,
} from "../../Api";
import { Notifications } from "../../Components/Toast";
import {
  CopyToClipboardButton,
  validateEthereumAddress,
} from "../../Constants/utils";
import OtpInput from "react-otp-input";

import { CommasRegex } from "../../Constants";
function Dashboard({ setShowFooter, setShowNavBar }) {
  const [walletaddressShow, setwalletaddressShow] = useState(false);
  const [ShowOTP, setShowOTP] = useState(false);
  const [OTP, setOTP] = useState();
  const [editwalletaddressShow, seteditwalletaddressShow] = useState(false);
  const [percentage, setPercentage] = useState();
  const [BonusToken, setBonusToken] = useState();
  const [bonusWithoutStage, setBonusWithOutSale] = useState();
  const [userBalance, setUserBalance] = useState();
  const navigate = useNavigate();

  const [saleGraph, setsaleGraph] = useState();
  const [wallerAddress, setWalletAddsress] = useState();
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  const [BNBvalue, setBNBValue] = useState();

  const [TotalRaised, setTotalRaised] = useState();

  const {
    CurrentStage,
    TotalSaleInStage,
    UserDetail,
    SetUserDetail,
    Promotioncode,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
  } = useContext(UserContest);
  useEffect(() => {
    getSumOfCurrencies()
    .then((res) => {
      setTotalRaised(res.data.totalraised);
    })
    .catch(() => {});
    if (CurrentStage?.id)
      getTokenBonusCount(CurrentStage?.id)
        .then((data) => {
          // console.log('getTokenBonusCount',data.total_tokens_purchased)
          setBonusToken(parseInt(data.total_tokens_purchased) + 0);
        })
        .catch(() => {});
    getBonusTokenCountWithOutStage()
      .then((data) => {
        setBonusWithOutSale(parseInt(data.total_tokens_purchased) + 0);
      })
      .catch(() => {});

    GraphTotal()
      .then((data) => {
        setsaleGraph(data.data.totalraised);
      })
      .catch(() => {});
  }, [CurrentStage]);
  useEffect(() => {
    let percentage =
      (parseInt(saleGraph) / parseInt(CurrentStage?.total_limit)) * 100;

    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)


    let remaining = parseInt(
      parseInt(CurrentStage?.total_limit) -
        (parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken) >
        parseInt(CurrentStage?.total_limit)
          ? parseInt(CurrentStage?.total_limit)
          : parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken))
    );
    let sold = parseInt(parseInt(TotalSaleInStage) + parseInt(BonusToken));
    setPercentage(parseFloat((sold / (remaining + sold)) * 100).toFixed(2));

    //  setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  }, [TotalSaleInStage, CurrentStage, saleGraph, BonusToken]);
  useEffect(() => {
    getTotalTokenOfUser(UserDetail?.id)
      .then((data) => {
        setUserBalance(data.data.total_tokens_purchased);
      })
      .catch(() => {});
    GetSelectedCurrencyPrice("bnbbsc")
      .then((price) => {
        // console.log(price,'price')
        setBNBValue(price);
      })
      .catch(() => {});
  }, [UserDetail]);

  const UpdateWalletAddress = () => {
    UserDetail.wallet_address = wallerAddress;
    UpdateProfileApi(UserDetail)
      .then(() => {
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem("JwtToken")) {
              localStorage.clear();
            }
            navigate("/");
          });
        Notifications("success", "Wallet Added Successfully");
      })
      .catch(() => {});
  };

  const Walletaddresschanage = () => {
    WalletaddressChanage(UserDetail?.id)
      .then(() => {
        Notifications("success", "OTP has sent to your Mail");
      })
      .catch(() => {});
  };
  const Walletaddressrequest = () => {
    const data = {
      user_id: UserDetail?.id,
      otp: OTP,
      wallet_address: wallerAddress,
    };
    WalletaddressRequest(data)
      .then(() => {
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem("JwtToken")) {
              localStorage.clear();
            }
            navigate("/");
          });
        setShowOTP(false);
        setOTP("");
        setwalletaddressShow(false);
        Notifications("success", "Wallet Added Successfully");
      })
      .catch(() => {
        // Notifications("success", "Entered OTP is incorrect");
      });
  };
  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" className="text-center">
                <h1>
                  <span> User Dashboard</span>
                </h1>
                <div className="spacer-10" />
              </Col>
              <div className="flex-contr lg">
                <div className="blue-post w-7 lg-f token-blnce-pnl flex-div-sm align-items-center">
                  <div className="stage-panel text-center">
                    <CircularProgressbar
                      value={percentage / 100}
                      maxValue={1}
                      text={`${percentage}%`}
                    />
                    {/* <img src={sold} alt="Data" /> */}
                  </div>

                  <div className="text-pnl">
                    <div className="small-bg-panel">
                      <h4 className="mt-0 font-weight-bold color">
                        {CurrentStage?.level}
                      </h4>
                      <div className="flex-div-sm text-left">
                        <p className="notranslate m-0 whitecolor">
                          Tokens remaining in stage:
                        </p>
                        <p className="notranslate m-0 whitecolor">
                        {parseFloat(
                          parseInt(
                            parseInt(CurrentStage?.total_limit) -
                              ( parseInt(TotalSaleInStage ?? 0)  

                              +
                               parseInt(BonusToken) 
                              
                              // +
                              //   parseInt(BonusToken) >
                              // parseInt(CurrentStage?.total_limit)
                              //   ? parseInt(CurrentStage?.total_limit)
                              //   : parseInt(TotalSaleInStage ?? 0) +
                              //     parseInt(BonusToken)
                                  
                                  
                                  )
                          )
                        )
                          ?.toString()
                          .replace(CommasRegex, ",")}
                        </p>
                      </div>
                      <div className="flex-div-sm text-left">
                        <p className="notranslate m-0 color">
                          Current stage price:
                        </p>
                        <p className="notranslate m-0 color">
                        {parseFloat(CurrentStage?.price_of_token ?? 0).toFixed(4)}{" "}
                         
                          <img
                            className="ml-1 img-sp"
                            src={iconutt}
                            alt="Icon Usd"
                            style={{ width: "20px" }}
                          />{" "}
                        </p>
                      </div>
                      <div className="flex-div-sm text-left">
                        <p className="notranslate m-0 whitecolor">
                          The next stage price:
                        </p>
                        <p className="notranslate m-0 whitecolor">
                        {parseFloat(CurrentStage?.futuretokenprice ?? 0).toFixed(4)}{" "}
                       
                          <img
                            className="ml-1 img-sp"
                            src={iconutt}
                            alt="Icon Usd"
                            style={{ width: "20px" }}
                          />{" "}
                        </p>
                      </div>
                    </div>
                    <div className="small-bg-panel">
                      <ul class="my-dotted-list w-100 m-0">
                        <li className="w-100">
                          <p class="m-0 font-weight-normal ">
                            Tokens Sold In Total:
                          </p>
                          <p class="m-0 font-weight-semibold lightgreycolor">
                           {parseInt(OverAllSaleToken ).toFixed(3)
                          ?.toString()
                          .replace(CommasRegex, ",") ?? 0}
                          </p>
                        </li>
                        <li className="w-100">
                          <p class="m-0 font-weight-normal "> Total Raised:</p>
                          <p class="m-0 font-weight-semibold lightgreycolor">
                            {" "}
                            {(
                          (parseInt(TotalRaised  )  +  (  parseInt(BonusToken) *CurrentStage?.price_of_token ) 
                          ) 
                         
                        )
                          .toFixed(3)
                          ?.toString()
                          .replace(CommasRegex, ",") ?? 0}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="spacer-20" />
                    <Link
                      className="reg-btn br-radius arrow-btn"
                      to="/PurchaseToken"
                    >
                      <Image src={circleArrow} alt="Arrow" /> Buy Tokens now
                    </Link>
                  </div>
                </div>
                <div className="blue-post w-5 lg-f web-view token-blnce-pnl">
                  <div className="small-bg-panel">
                    <h4 className="color font-weight-bold ">
                      My Token Balance
                      <span className="tooltip-span">
                        <img src={iconi} alt="Icon i" />
                        <span>
                          Equivalent to ~{" "}
                          { parseFloat(  CurrentStage?.price_of_token * userBalance ?? 0 ).toFixed(2) } USD
                      
                          </span>
                      </span>
                    </h4>
                    <div className="d-flex">
                      <h4 className="font-weight-bold ">
                        <img className="mr-1" src={iconlogo} alt="Icon Logo" />{" "}
                        {userBalance ?? 0}
                      </h4>
                    </div>
                    {/* <div className="top-line">
                      {Promotioncode?.name && (
                        <p>
                          Use promo <span>"{Promotioncode?.name}"</span> to get{" "}
                          {Promotioncode?.bonus}% <span>bonus!</span>
                        </p>
                      )}
                    </div> */}
                  </div>
                  <div className="small-bg-panel">
                    <h4 className="color font-weight-bold ">
                      Balance in other currencies:
                    </h4>
                    <div className="spacer-10" />

                    <ul className="my-dotted-list w-100">
                      <li className="w-100">
                        <p className="notranslate m-0 font-weight-normal whitecolor">
                          <img
                            className="ml-1 img-sp"
                            src={iconusd}
                            alt="Icon Usd"
                          />{" "}
                          USD{" "}
                        </p>
                        <span />
                        <p className="notranslate m-0 font-weight-bold">0</p>
                      </li>
                      <li className="w-100">
                        <p className="notranslate m-0 font-weight-normal whitecolor">
                          <img
                            className="ml-1 img-sp"
                            src={iconeth}
                            alt="Icon Eth"
                          />{" "}
                          ETH{" "}
                        </p>
                        <span />
                        <p className="notranslate m-0 font-weight-bold">0</p>
                      </li>
                      <li className="w-100">
                        <p className="notranslate m-0 font-weight-normal whitecolor">
                          <img
                            className="ml-1 img-sp"
                            src={iconbnb}
                            alt="Icon BNB"
                          />{" "}
                          BNB{" "}
                        </p>
                        <span />
                        <p className="notranslate m-0 font-weight-bold">0</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <div className="flex-contr web-view">
                <div className="blue-post w-5 lg-h">
                  <div className="spacer-10" />
                  <div className="flex-div">
                    <h6 className="m-0 color font-weight-medium">
                      Account Status
                    </h6>
                    <p className="m-0 font-weight-bold">
                      <img src={check} alt="Verified" /> Verified
                    </p>
                  </div>
                  <div className="spacer-10" />
                </div>
                <div className="blue-post w-7 lg-h">
                  <div className="spacer-10" />
                  <div className="flex-div-sm text-left">
                    <h6 className="m-0 color font-weight-medium">
                      Receiving Wallet
                    </h6>
                    {UserDetail?.wallet_address === "null" ||
                      (UserDetail?.wallet_address === null && (
                        <Button
                          className="simple-link purple font-qs tooltip-span"
                          onClick={() => {
                            setwalletaddressShow(true);
                          }}
                        >
                          Add your wallet address
                        </Button>
                      ))}

                    <div className="d-flex notranslate">
                      {UserDetail?.wallet_address !== "null" &&
                        UserDetail?.wallet_address && (
                          <>
                            <p
                              onClick={() =>
                                CopyToClipboardButton(
                                  UserDetail?.wallet_address,
                                  "Address Copied Successfully"
                                )
                              }
                              className="m-0 cursor"
                            >{`${UserDetail?.wallet_address?.slice(
                              0,
                              5
                            )}...${UserDetail?.wallet_address?.slice(-5)}`}</p>
                            <Button
                              className="simple-link purple font-qs ml-2"
                              onClick={() => setwalletaddressShow(true)}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" className="tablet-view">
                <div className="blue-post token-blnce-pnl">
                  <div className="small-bg-panel">
                    <h4 className="color font-weight-bold ">
                      My Token Balance
                      <span className="tooltip-span">
                        <img src={iconi} alt="Icon i" />
                        <span>
                          Equivalent to ~{" "}
                          { parseFloat(  CurrentStage?.price_of_token * userBalance ?? 0 ).toFixed(2) } USD
                        </span>
                      </span>
                    </h4>
                    <div className="d-flex">
                      <h4 className="font-weight-bold ">
                        <img className="mr-1" src={iconlogo} alt="Icon Logo" />{" "}
                        {userBalance ?? 0}
                      </h4>
                    </div>
                    {/* <div className="top-line">
                      {Promotioncode?.name && (
                        <p>
                          Use promo <span>"{Promotioncode?.name}"</span> to get{" "}
                          {Promotioncode?.bonus}% <span>bonus!</span>
                        </p>
                      )}
                    </div> */}
                  </div>
                  <div className="small-bg-panel">
                    <h4 className="color font-weight-bold ">
                      Balance in other currencies:
                    </h4>
                    <div className="spacer-10" />

                    <ul className="my-dotted-list w-100">
                      <li>
                        <p className="notranslate m-0 font-weight-normal whitecolor">
                          <img
                            className="ml-1 img-sp"
                            src={iconusd}
                            alt="Icon Usd"
                          />{" "}
                          USD{" "}
                        </p>
                        <span />
                        <p className="notranslate m-0 font-weight-bold">0</p>
                      </li>
                      <li>
                        <p className="notranslate m-0 font-weight-normal whitecolor">
                          <img
                            className="ml-1 img-sp"
                            src={iconeth}
                            alt="Icon Eth"
                          />{" "}
                          ETH{" "}
                        </p>
                        <span />
                        <p className="notranslate m-0 font-weight-bold">0</p>
                      </li>
                      <li>
                        <p className="notranslate m-0 font-weight-normal whitecolor">
                          <img
                            className="ml-1 img-sp"
                            src={iconbnb}
                            alt="Icon BNB"
                          />{" "}
                          BNB{" "}
                        </p>
                        <span />
                        <p className="notranslate m-0 font-weight-bold">0</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col xl="6" lg="6" md="6" className="tablet-view">
                <div className="blue-post">
                  <div className="spacer-10" />
                  <div className="flex-div">
                    <h6 className="m-0 color font-weight-medium">
                      Account Status
                    </h6>
                    <p className="m-0 font-weight-bold">
                      <img src={check} alt="Verified" /> Verified
                    </p>
                  </div>
                  <div className="spacer-10" />
                </div>
                <div className="blue-post">
                  <div className="flex-div-sm text-left">
                    <h6 className="m-0 color font-weight-medium">
                      Receiving Wallet
                    </h6>
                    {UserDetail?.wallet_address === "null" ||
                      (UserDetail?.wallet_address === null && (
                        <Button
                          className="simple-link purple font-qs tooltip-span"
                          onClick={() => {
                            setwalletaddressShow(true);
                          }}
                        >
                          Add your wallet address
                        </Button>
                      ))}

                    <div className="d-flex notranslate">
                      {UserDetail?.wallet_address !== "null" &&
                        UserDetail?.wallet_address && (
                          <>
                            <p
                              onClick={() =>
                                CopyToClipboardButton(
                                  UserDetail?.wallet_address,
                                  "Address Copied Successfully"
                                )
                              }
                              className="m-0 cursor"
                            >{`${UserDetail?.wallet_address?.slice(
                              0,
                              5
                            )}...${UserDetail?.wallet_address?.slice(-5)}`}</p>
                            <Button
                              className="simple-link purple font-qs ml-2"
                              onClick={() => setwalletaddressShow(true)}
                            >
                              Edit
                            </Button>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
      
          </Container>
        </Row>
      </section>

      {/* Wallet Address Modal Starts Here */}

      <Modal
        size="lg"
        show={walletaddressShow}
        centered
        onHide={() => setwalletaddressShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setwalletaddressShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <div className="text-center">
            <h3 className="text-bold">
              Wallet <span className="color">Address</span>
            </h3>
          </div>
          <p className="greycolor">
            To receive your <b>$INTL</b>, you will need to enter your Ethereum
            wallet address in the box below. You can claim your <b>$INTL</b>{" "}
            when the presale ends.
          </p>
          <div className="spacer-20" />

          <Form>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <i>Enter Wallet Address</i>
              </Form.Label>
              <Form.Control
                type="text"
                value={wallerAddress}
                onChange={(e) => {
                  setWalletAddsress(e.target.value);
                }}
                autoComplete="new-email"
                className="input-email border-rad-0"
                placeholder="Enter Your Address"
              />
              {wallerAddress && !validateEthereumAddress(wallerAddress) && (
                <p style={{ color: "red" }}>Please enter a valid Address</p>
              )}
            </Form.Group>
          </Form>
          <div className="d-flex align-items-start mb-2">
            <img className="mr-2" src={iconired} alt="Icon wallet" />
            <p className="greycolor m-0">
              Address should be <b>ERC-20</b> compliant.
            </p>
          </div>
          <div className="d-flex align-items-start">
            <img className="mr-2" src={iconired} alt="Icon i" />
            <p className="greycolor">
              <b>Do NOT</b> use your exchange wallet address or a wallet for
              which you do not have a private key. If you do, you will not
              receive your Tokens and could lose your funds.
            </p>
          </div>
          <div className="spacer-30" />
          <div className="text-center">
            <Button
              disabled={!validateEthereumAddress(wallerAddress)}
              onClick={() => {
                Walletaddressrequest();
                //  setShowOTP(true)
                //  setOTP(false)
              }}
              className="reg-btn br-radius arrow-btn letter-spacing-1"
            >
              <Image src={circleArrow} alt="Arrow" /> Add Wallet address
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={ShowOTP}
        centered
        onHide={() => setShowOTP(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button className="close-btn" onClick={() => setShowOTP(false)}>
            <i className="fa fa-close"></i>
          </Button>
          <h4 style={{ textAlign: "center" }}>
            <span className="color">OTP</span> has sent to your{" "}
            <span className="color">Registered</span> Email
          </h4>
          <div className="otpinput">
            <OtpInput
              value={OTP}
              onChange={setOTP}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <br />
          </div>

          <div className="text-center">
            <a
              className="text-center simple-link"
              onClick={() => Walletaddresschanage()}
              style={{ cursor: "pointer" }}
            >
              Resend OTP
            </a>
            <div className="spacer-20" />
            <Button
              onClick={() => {
                Walletaddressrequest();
              }}
              className="reg-btn br-radius arrow-btn"
            >
              <Image src={circleArrow} alt="Arrow" /> Add Wallet address
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default Dashboard;
