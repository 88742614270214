import React from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Image } from "react-bootstrap";

function CommingSoon() {
  return (
    <>
      <div className="comminsoon-panel">
        <div className="full-div">
          <h1>Coming Soon</h1>
<div className="spacer-30"/>
          <Link className="reg-btn br-radius mb-1 arrow-btn" to="/">Back To Home</Link>
        </div>
      </div>
    </>
  );
}
export default CommingSoon;