import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Container, Col, Image } from "react-bootstrap";
import Frequentlyquestion from "../Components/Frequentlyquestion";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import JoinIndustry from "../Assets/img/Home/Join-Industry.png";
import stargrid1 from "../Assets/img/Bg/Grid-bg-2.png";
import ReducedTradingFee from "../Assets/img/Home/Reduced-Trading-Fee.png";
import GovernanceRights from "../Assets/img/Home/Governance-Rights.png";
import AccesstoExclusiveAgents from "../Assets/img/Home/Access-to-Exclusive-Agents.png";
import Header from "../Components/Header";
import Tokenomics from "../Components/Tokenomics";
import UserContest from "../ContextAPI/UserContest";
import { useAccount, useDisconnect } from "wagmi";
import Ourpartner from "../Components/Ourpartner";
import PoweringNext from "../Components/PoweringNext";
import GlobalPostsPnl from "../Components/GlobalPostsPnl";
import SpeedPost from "../Components/SpeedPost";
import Developmentpost from "../Components/Developmentpost";
import RoadMap from "../Components/RoadMap";
function Home({
  setSignupShow,
  RewardSectionRef,
  TokenSectionRef,
  RoadSectionRef,
  FAQeSectionRef,
  SignupShow,
  featuredSectionRef,
  setShowNavBar,
  setShowFooter,
}) {
  const { CurrentStage, TotalSaleInStage } = useContext(UserContest);
  useEffect(() => {
    setShowNavBar(true);
    setShowFooter(true);
  }, []);
  const navigate = useNavigate();
  const { address, isConnecting, isDisconnected } = useAccount();
  // RewardSectionRef={RewardSectionRef}
  // TokenSectionRef={TokenSectionRef}
  // RoadSectionRef={RoadSectionRef}
  // WhiteSectionRef={WhiteSectionRef}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);

  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);

  return (
    <>
      <main className="home-main">
        {/* Header Starts Here */}
        <Header setSignupShow={setSignupShow} SignupShow={SignupShow} />
        {/* Header Starts Here */}

        {/* Our Partner Starts Here */}
        <Container fluid>
          <Row>
            <Container>
              <Row>
                <Col xl="12">
                  <Ourpartner />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        {/* Our Partner Ends Here */}

        {/* Powering Next Starts Here */}
        <section className="container-fluid Powering-next-pnl">
          <div
            onClick={() => {
              setSignupShow(true);
            }}
            className="bg-layer"
            style={{ backgroundImage: `url(${stargrid1})` }}
          />
          <PoweringNext setSignupShow={setSignupShow} />
        </section>
        {/* Powering Next Ends Here */}

        {/* Globe Posts Starts Here */}
        <section
          onClick={() => {
            setSignupShow(true);
          }}
          className="container-fluid globe-posts-pnl"
        >
          <GlobalPostsPnl />
        </section>
        {/* Globe Posts Ends Here */}

        {/* Join The Industry Starts Here */}
        <div className="join-indutry-pnl">
          <Container fluid>
            <Row>
              <Container>
                <Row>
                  <Col xl="6" lg="6" md="6" className="scroll-anime left">
                    <Image src={JoinIndustry} alt="Industry" />
                    <div className="spacer-50"/>
                  </Col>
                  <Col xl="6" lg="6" md="6" className="scroll-anime right">
                    <div className="spacer-50 web-view" />
                    <h2 className="whitecolor">
                    Compete With Over 12,000 Traders - INTL Leaderboard 
                    </h2>
                    <p className="lightcolor">
                    Our leaderboard features a real-time list of top performers in the crypto world. Top the charts with exceptional volume and profitability to get features on the leaderboard. Top performers get access to reduced trading fees and perks. 

                    </p>
                  </Col>
                </Row>
              </Container>
            </Row>
          </Container>
          {/* <div className="global-post reverse">
            <div
              className="bg-layer"
              style={{ backgroundImage: `url(${JoinIndustry})` }}
            />
            <div className="img-pnl">
              <Image src={JoinIndustry} alt="Industry" />
            </div>
            <div className="txt-pnl">
              <h2 className="whitecolor">
                Join The Industry-Leading Decentralized Ecosystem  
              </h2>
              <p className="lightcolor">
                With manually designed systems prone to vulnerabilities,
                IntelMarkets is taking security and decentralization to the next
                level. Our platform allows you to trade over 100,000+ crypto
                assets without any KYC or identity verification requirements.
                Additionally, we’re expanding the horizons of decentralization
                with over 500 market makers powering liquidity for our trading
                ecosystem.
              </p>
            </div>
          </div> */}
          <Container
            onClick={() => {
              setSignupShow(true);
            }}
            fluid
          >
            <Row>
              <Container>
                <Row>
                  <Col xl="12" className="scroll-anime up">
                    <div className="spacer-50 web-view" />
                    <div className="spacer-50" />
                    <h2 className="whitecolor">
                    The Central Block of IntelMarkets - The 

                      <br /> $INTL Token 
                    </h2>
                    <div className="spacer-20" />
                    <div className="flex-div-sm align-items-end">
                      <p className="m-0">
                      The INTL token powers the IntelMarkets ecosystem with utility across staking, governance, and management. 

                      </p>
                      <Link
                        className="reg-btn mobile-view-margin br-radius arrow-btn"
                        href="#"
                        style={{ minWidth: "155px" }}
                      >
                        {" "}
                        <Image src={circleArrow} alt="Arrow" /> Join Presale
                      </Link>
                    </div>
                    <div className="spacer-50" />
                    <div className="spacer-20" />
                  </Col>
                  <Col xl="12" lg="12" md="12" className="scroll-anime down">
                    <div className="partner-post-main">
                      <div className="partner-post">
                        <Image
                          src={ReducedTradingFee}
                          alt="Reduced Trading Fee"
                        />
                        <div className="txt-pnl">
                          <h5>Reduced Trading Fee
                          </h5>
                          <p>
                          Access up to 30% discounts on trading fees by holding the INTL token. 

                          </p>
                        </div>
                      </div>
                      <div className="partner-post">
                        <Image src={GovernanceRights} alt="Governance Rights" />
                        <div className="txt-pnl">
                          <h5>Prioritized Feature Rollout
                          </h5>
                          <p>
                          Be the first to experience new IntelMarkets products and features with our Launchpad program. 

                          </p>
                        </div>
                      </div>
                      <div className="partner-post withbg">
                        <span className="circle-span"></span>
                        <Image
                          src={AccesstoExclusiveAgents}
                          alt="Access to Exclusive Agents"
                        />
                        <div className="txt-pnl">
                          <h5>Governance Rights
                          </h5>
                          <p>
                          Qualifying INTL holders will be eligible to give input on upcoming developments and vote on proposals. 

                          </p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
          </Container>
        </div>
        {/* Join The Industry Ends Here */}

        {/* INTL Tokenomics Starts Here */}
        <section
          onClick={() => {
            setSignupShow(true);
          }}
          ref={TokenSectionRef}
          className="container-fluid tokenomics-panel"
        >
          <Row>
            <Container>
              <Row>
                <Tokenomics />
              </Row>
            </Container>
          </Row>
        </section>
        {/* INTL Tokenomics Ends Here */}

        {/* Roadmap Starts Here */}
        <Container fluid>
          <Row>
            <Container>
              <Row>
                <Col
                  xl={{ span: 10, offset: 1 }}
                  lg={{ span: 10, offset: 1 }}
                  md={{ span: 10, offset: 1 }}
                  className="text-center scroll-anime down"
                >
                  <h2 className="whitecolor">Roadmap</h2>
                  <p>
                    At IntelMarkets, we’re focused on expanding the applications
                    of AI technology at every layer of our ecosystem. Our
                    roadmap is designed to give you a strategic insight into our
                    key milestones and reflects our focus on revamping the way
                    blockchain technology is deployed in the fintech and trading
                    industry.
                  </p>
                </Col>
              </Row>
            </Container>
            <Col xl="12" className="scroll-anime left">
              <div className="roadmap-slider">
                <RoadMap />
              </div>
            </Col>
          </Row>
        </Container>
        {/* Roadmap Ends Here */}

        {/* Speed Posts Panel Starts Here */}
        <section
          onClick={() => {
            setSignupShow(true);
          }}
          className="container-fluid text-center"
        >
          <Row>
            <Container>
              <Row>
                <Col xl="12" className="scroll-anime down">
                  <h2 className="m-0">INTL Square - Exclusive Benefits </h2>
                  <div className="spacer-50" />
                </Col>
                <Col
                  xxl={{ span: 10, offset: 1 }}
                  xl={{ span: 12, offset: 0 }}
                  lg={{ span: 12, offset: 0 }}
                  className="scroll-anime up"
                >
                  <SpeedPost />
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        {/* Speed Posts Panel Ends Here */}

        {/* Trade However Starts Here */}
        <section className="container-fluid text-center">
          <Row>
            <Container>
              <Row>
                <Col xl="12" className="scroll-anime down">
                  <h2 className="light">Development Updates</h2>
                  <div className="spacer-30" />
                </Col>
                <Developmentpost />
              </Row>
            </Container>
          </Row>
        </section>
        {/* Trade However Ends Here */}

        {/* Frequently Question Panel Starts Here */}
        <section
          ref={FAQeSectionRef}
          className="container-fluid text-center frequently-question-panel"
        >
          <Row>
            <Container>
              <Row>
                <Col xl="12" lg="12" className="scroll-anime down">
                  <h2>Frequently Asked Questions.</h2>
                </Col>
                <Col
                  xxl={{ span: 6, offset: 3 }}
                  xl={{ span: 10, offset: 1 }}
                  lg={{ span: 10, offset: 1 }}
                >
                  <Frequentlyquestion />
                </Col>
              </Row>
            </Container>
          </Row>
        </section>
        {/* Frequently Question Panel Ends Here */}
      </main>
    </>
  );
}
export default Home;
