import React, { useEffect } from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import card from "../Assets/img/card-3.png";
import { Link } from "react-router-dom";
function DevDetails( {  setctaModal}) {
  useEffect(()=>{
    window.scrollTo(0, 0);
},[])
  return (
    <>
      {/* Dev Details Starts Here */}
      <section  onClick={()=>{
        setctaModal(true)
      }} className="container-fluid inner-page">
        <Row>
          <Container fluid className="scroll-anime down">
            <Row>
              <Container>
                <Row>
                  <Col xl="12" lg="12" md="12" className="text-center">
                    <h1 className="whitecolor">
                      {" "}
                      Intel Markets - Development Update 2
                    </h1>
                    <div className="spacer-30" />
                    <div className="Dev-details-img text-center">
                      <Image src={card} alt="card" />
                    </div>
                  </Col>

                  <Col xl="12" lg="12" md="12">
                    <div className="sticky-text-container">
                      <div className="sticky-pnl">
                        <h3 className="light">Table of content</h3>
                        <div className="spacer-20" />
                        <ul className="dot-list">
                          <li>
                            <Link to="#core-system">
                              {" "}
                              Core System Architecture Optimization...
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              {" "}
                              Social Media Tracking Integration for...
                            </Link>
                          </li>
                          <li>
                            <Link to=""> Evaluating Community Forums</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="txt-pnl">
                        <p>Hey everyone!</p>
                        <p>
                          We’ve made significant progress this week in the
                          development and expansion of Intel Markets. The key
                          focus area was the initiation of the exchange
                          development, which included fundamental groundwork for
                          our main features. Given the technical challenge of
                          implementing a proprietary blockchain as the core of
                          our platform, we had to creatively explore different
                          options and figure out the best ways to move forward.
                        </p>
                        <h4 id="core-system">
                          Core System Architecture Optimization for Loads
                        </h4>
                        <p>
                          This week's key focus area was to test the fundamental
                          technical infrastructure of the upcoming trading
                          platform. Our current architectures use a combination
                          of cloud-based servers and our proprietary layer 1 to
                          establish a unique deployment structure for our AI
                          algorithms. The development team agreed to focus on
                          modularity while maintaining extensibility for our
                          upcoming features.
                        </p>
                        <h4>
                          Social Media Tracking Integration for Smart
                          Retargeting
                        </h4>
                        <p>
                          Our marketing team had an extensive session with the
                          development team to implement retargeting across our
                          key channels. The goal was to optimize marketing costs
                          by delivering native retargeting to our users.
                          Additionally, the team brainstormed an initial
                          framework for our social trading features that will
                          allow traders to build copy-trading communities.
                        </p>
                        <h4>Evaluating Community Forums</h4>
                        <p>
                          One of the critical priorities for Intel Market is to
                          create a trading platform that is tailored to user
                          requirements. As part of that focus, our team explored
                          different options to channel community input,
                          including a developers forum and beta testing groups.
                        </p>
                        <p>
                          Additionally, other options will be constantly
                          evaluated to create the most effective channels for
                          user feedback.
                        </p>
                        <p>Stay tuned!</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Dev Details Ends Here */}
    </>
  );
}
export default DevDetails;
