import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import speed1 from "../Assets/img/Home/speed-1.png";
import speed2 from "../Assets/img/Home/speed-2.png";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

function RoadMap() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1201, min: 991 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 991, min: 0 },
      items: 1,
    },
  };
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <>
      {/* <Slider {...settings}>
        <div>
          <div className="roadmap-post-main-container">
            <div className="roadmap-post-main">
              <h4>Q3 2024 - Genesis</h4>
              <span></span>

              <div className="roadmap-post">
                <ul>
                  <li>
                    Defining an initial vision for IntelMarkets and the Zirkon
                    blockchain.
                  </li>
                  <li>
                    Identifying key milestones and objectives for the platform.
                  </li>
                  <li>
                    Shaping the initial team with industry-leading experts.
                  </li>
                </ul>
              </div>
            </div>
            <div className="roadmap-post-main">
              <h4>Q4 - 2024 Foundations</h4>
              <span></span>

              <div className="roadmap-post">
                <ul>
                  <li>
                    Initializing the technical base for the IntelMarkets
                    platform.
                  </li>
                  <li>Segmenting teams with advanced requirements.</li>
                  <li>
                    Launching a marketing campaign for traction and awareness.
                  </li>
                  <li>
                    Elaborating on key events in Dubai and Singapore for market
                    partnerships.
                  </li>
                  <li>
                    Launching the $1 Million fund for Route X21 development.
                  </li>
                  <li>Launch public presale round.</li>
                </ul>
              </div>
            </div>
            <div className="roadmap-post-main">
              <h4>Q1 - 2025 Takeoff</h4>
              <span></span>

              <div className="roadmap-post">
                <ul>
                  <li>
                    Expanding the Intell-M deployment with licensing and data
                    feeds from key institutional stakeholders.
                  </li>
                  <li>
                    Initiating the H100 ecosystem for compute expansion and
                    native AI integration.
                  </li>
                  <li>
                    Initiating the objectives and framework for the Zirkon
                    testnet.
                  </li>
                  <li>
                    Expanding marketing across visual mediums for broader
                    traction.
                  </li>
                  <li>
                    Establishing partnerships with Meta and OpenAI for exclusive
                    AI deployment.
                  </li>
                  <li>Launching a $1 Million community giveaway.</li>
                </ul>
              </div>
            </div>
            <div className="roadmap-post-main">
              <h4>Q2 - 2025 Ignition</h4>
              <span></span>
              <div className="roadmap-post">
                <ul>
                  <li>
                    Begin the deployment of the Zirkon Layer 1 blockchain for
                    testnet
                  </li>
                  <li>Expanding marketing efforts with team reveal.</li>
                  <li>
                    Announcing the giveaway winners for social traction and
                    community activation.
                  </li>
                  <li>
                    Launching the initial version of the Route X21 wallet
                    product.
                  </li>
                  <li>
                    Launching the Airdrop fund for the IntelMarkets community.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="roadmap-post-main-container">
            <div className="roadmap-post-main">
              <h4>Q3 2024 - Genesis</h4>
              <span></span>

              <div className="roadmap-post">
                <ul>
                  <li>
                    Defining an initial vision for IntelMarkets and the Zirkon
                    blockchain.
                  </li>
                  <li>
                    Identifying key milestones and objectives for the platform.
                  </li>
                  <li>
                    Shaping the initial team with industry-leading experts.
                  </li>
                  
                </ul>
              </div>
            </div>
            <div className="roadmap-post-main">
              <h4>Q4 - 2024 Foundations</h4>
              <span></span>

              <div className="roadmap-post">
                <ul>
                  <li>
                    Initializing the technical base for the IntelMarkets
                    platform.
                  </li>
                  <li>Segmenting teams with advanced requirements.</li>
                  <li>
                    Launching a marketing campaign for traction and awareness.
                  </li>
                  <li>
                    Elaborating on key events in Dubai and Singapore for market
                    partnerships.
                  </li>
                  <li>
                    Launching the $1 Million fund for Route X21 development.
                  </li>
                  <li>Launch public presale round.</li>
                </ul>
              </div>
            </div>
            <div className="roadmap-post-main">
              <h4>Q1 - 2025 Takeoff</h4>
              <span></span>

              <div className="roadmap-post">
                <ul>
                  <li>
                    Expanding the Intell-M deployment with licensing and data
                    feeds from key institutional stakeholders.
                  </li>
                  <li>
                    Initiating the H100 ecosystem for compute expansion and
                    native AI integration.
                  </li>
                  <li>
                    Initiating the objectives and framework for the Zirkon
                    testnet.
                  </li>
                  <li>
                    Expanding marketing across visual mediums for broader
                    traction.
                  </li>
                  <li>
                    Establishing partnerships with Meta and OpenAI for exclusive
                    AI deployment.
                  </li>
                  <li>Launching a $1 Million community giveaway.</li>
                </ul>
              </div>
            </div>
            <div className="roadmap-post-main">
              <h4>Q2 - 2025 Ignition</h4>
              <span></span>
              <div className="roadmap-post">
                <ul>
                  <li>
                    Begin the deployment of the Zirkon Layer 1 blockchain for
                    testnet
                  </li>
                  <li>Expanding marketing efforts with team reveal.</li>
                  <li>
                    Announcing the giveaway winners for social traction and
                    community activation.
                  </li>
                  <li>
                    Launching the initial version of the Route X21 wallet
                    product.
                  </li>
                  <li>
                    Launching the Airdrop fund for the IntelMarkets community.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Slider> */}
      <Slider {...settings}>
        <div>
          <div className="roadmap-post-main">
            <h4>Q3 2024 - Genesis</h4>
            <span></span>

            <div className="roadmap-post">
              <ul>
                <li>
                  Defining an initial vision for IntelMarkets and the Zirkon
                  blockchain.
                </li>
                <li>
                  Identifying key milestones and objectives for the platform.
                </li>
                <li>Shaping the initial team with industry-leading experts.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="roadmap-post-main">
            <h4>Q4 - 2024 Foundations</h4>
            <span></span>

            <div className="roadmap-post">
              <ul>
                <li>
                  Initializing the technical base for the IntelMarkets platform.
                </li>
                <li>Segmenting teams with advanced requirements.</li>
                <li>
                  Launching a marketing campaign for traction and awareness.
                </li>
                <li>
                  Elaborating on key events in Dubai and Singapore for market
                  partnerships.
                </li>
                <li>
                  Launching the $1 Million fund for Route X21 development.
                </li>
                <li>Launch public presale round.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="roadmap-post-main">
            <h4>Q1 - 2025 Takeoff</h4>
            <span></span>

            <div className="roadmap-post">
              <ul>
                <li>
                  Expanding the Intell-M deployment with licensing and data
                  feeds from key institutional stakeholders.
                </li>
                <li>
                  Initiating the H100 ecosystem for compute expansion and native
                  AI integration.
                </li>
                <li>
                  Initiating the objectives and framework for the Zirkon
                  testnet.
                </li>
                <li>
                  Expanding marketing across visual mediums for broader
                  traction.
                </li>
                <li>
                  Establishing partnerships with Meta and OpenAI for exclusive
                  AI deployment.
                </li>
                <li>Launching a $1 Million community giveaway.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="roadmap-post-main">
            <h4>Q2 - 2025 Ignition</h4>
            <span></span>
            <div className="roadmap-post">
              <ul>
                <li>
                  Begin the deployment of the Zirkon Layer 1 blockchain for
                  testnet
                </li>
                <li>Expanding marketing efforts with team reveal.</li>
                <li>
                  Announcing the giveaway winners for social traction and
                  community activation.
                </li>
                <li>
                  Launching the initial version of the Route X21 wallet product.
                </li>
                <li>
                  Launching the Airdrop fund for the IntelMarkets community.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="roadmap-post-main">
            <h4>Q3 2024 - Genesis</h4>
            <span></span>

            <div className="roadmap-post">
              <ul>
                <li>
                  Defining an initial vision for IntelMarkets and the Zirkon
                  blockchain.
                </li>
                <li>
                  Identifying key milestones and objectives for the platform.
                </li>
                <li>Shaping the initial team with industry-leading experts.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="roadmap-post-main">
            <h4>Q4 - 2024 Foundations</h4>
            <span></span>

            <div className="roadmap-post">
              <ul>
                <li>
                  Initializing the technical base for the IntelMarkets platform.
                </li>
                <li>Segmenting teams with advanced requirements.</li>
                <li>
                  Launching a marketing campaign for traction and awareness.
                </li>
                <li>
                  Elaborating on key events in Dubai and Singapore for market
                  partnerships.
                </li>
                <li>
                  Launching the $1 Million fund for Route X21 development.
                </li>
                <li>Launch public presale round.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="roadmap-post-main">
            <h4>Q1 - 2025 Takeoff</h4>
            <span></span>

            <div className="roadmap-post">
              <ul>
                <li>
                  Expanding the Intell-M deployment with licensing and data
                  feeds from key institutional stakeholders.
                </li>
                <li>
                  Initiating the H100 ecosystem for compute expansion and native
                  AI integration.
                </li>
                <li>
                  Initiating the objectives and framework for the Zirkon
                  testnet.
                </li>
                <li>
                  Expanding marketing across visual mediums for broader
                  traction.
                </li>
                <li>
                  Establishing partnerships with Meta and OpenAI for exclusive
                  AI deployment.
                </li>
                <li>Launching a $1 Million community giveaway.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div className="roadmap-post-main">
            <h4>Q2 - 2025 Ignition</h4>
            <span></span>
            <div className="roadmap-post">
              <ul>
                <li>
                  Begin the deployment of the Zirkon Layer 1 blockchain for
                  testnet
                </li>
                <li>Expanding marketing efforts with team reveal.</li>
                <li>
                  Announcing the giveaway winners for social traction and
                  community activation.
                </li>
                <li>
                  Launching the initial version of the Route X21 wallet product.
                </li>
                <li>
                  Launching the Airdrop fund for the IntelMarkets community.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}
export default RoadMap;
