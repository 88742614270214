"use client";
import React, { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import shadowborder from "../Assets/img/shadow-border.png";
import Partner1 from "../Assets/img/Home/Partners-6.png";
import Partner2 from "../Assets/img/Home/Partners-5.png";
import Partner3 from "../Assets/img/Home/Partners-4.png";
import Partner4 from "../Assets/img/Home/Partners-3.png";
import Partner5 from "../Assets/img/Home/Partners-2.png";
import Partner6 from "../Assets/img/Home/Partners-1.png";
function Ourpartner() {
  return (
    <>
      <div className="marquee">
        <h6 class="border-h">Our Partners</h6>
        <Image src={shadowborder} alt="Shadow Border" />
        <div className="spacer-10" />
        <ul className="upcoming-matches-list">
          <li>
            <div className="Marquee-post">
              <Image src={Partner1} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner2} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner3} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner4} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner5} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner6} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner1} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner2} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner3} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner4} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner5} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner6} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner1} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner2} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner3} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner4} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner5} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner6} alt="Partner" />
            </div>
          </li>

          <li>
            <div className="Marquee-post">
              <Image src={Partner1} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner2} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner3} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner4} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner5} alt="Partner" />
            </div>
          </li>
          <li>
            <div className="Marquee-post">
              <Image src={Partner6} alt="Partner" />
            </div>
          </li>
        </ul>
        <div className="spacer-50" />
      </div>
    </>
  );
}
export default Ourpartner;
