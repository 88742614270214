import React, { useEffect } from "react";
import { Row, Container, Col, Image } from "react-bootstrap";
import card from "../Assets/img/Home/devimgMain1ada.png";
import { Link } from "react-router-dom";
function DevDetails2({setSignupShow}) {

    useEffect(()=>{
             window.scrollTo(0, 0);
    },[])
  return (
    <>
      {/* Dev Details Starts Here */}
      <section  onClick={()=>{
        setSignupShow(true)
      }} className="container-fluid inner-page">
        <Row>
          <Container fluid className="scroll-anime down">
            <Row>
              <Container>
                <Row>
                  <Col xl="12" lg="12" md="12" className="text-center">
                    <h1 className="whitecolor">
                      {" "}
                      Intel Markets - Development Update 1
                    </h1>
                    <div className="spacer-30" />
                    <div className="Dev-details-img text-center">
                      <Image src={card} alt="card" />
                    </div>
                  </Col>

                  <Col xl="12" lg="12" md="12">
                    <div className="sticky-text-container">
                      <div className="sticky-pnl">
                        <h3 className="light">Table of content</h3>
                        <div className="spacer-20" />
                        <ul className="dot-list">
                          <li>
                            <Link to="#core-system">
                              {" "}

                            



Implementing Cutting-Edge AI and Mach...
                            </Link>
                          </li>
                          <li>
                            <Link to="">
                              {" "}
                              Creating a Cross-functional Developme...
                            </Link>
                          </li>
                          <li>
                            <Link to=""> Expanding the V1 Interface for Public...</Link>
                          </li>
                          <li>
                            <Link to="">                      Keep Up For More Developments</Link>
                          </li>
     
                        </ul>
                      </div>
                      <div className="txt-pnl">
                    
                        <p>
                        We’re excited to reveal the first development update for our Intel Markets platform. These updates will be essential to our goal of unveiling the future of crypto trading. Intel Markets is the world’s first AI-powered smart trading platform powered by trading bots and smart algorithms.


                        </p>
                        <p>
                        This first development update will cover our recent progress, and we’re excited to provide further updates about our product with these regular releases.
                        </p>
                        <h4 id="core-system">
                        Implementing Cutting-Edge AI and Machine Learning
                        </h4>
                        <p>
                        As a future-ready platform, Intel Markets was designed to give traders an edge through our advanced trading robots. To achieve this goal, we’ve deployed an advanced infrastructure that relies on decentralized GPU resources and our proprietary trading algorithms that have evaluated over 100,000 data points to develop the most extensive trading robots in the industry.
                        </p>
                        <h4>
                        Creating a Cross-functional Development Team
                        </h4>
                        <p>
                        We’ve assembled a team of cross-functional industry experts from finance, blockchain, and fintech companies to deploy the most advanced trading platform in the industry. Our development team also features experts who are focused on expanding the implementation of the fastest value transfer platform. The deployment of over 652 unique processing nodes will facilitate seamless payments of billions of users.


                        </p>
                        <h4>Expanding the V1 Interface for Public Launch</h4>
                        <p>
                        This week, our development team's core focus is deploying the V1 interface to introduce Intel Markets to the world. The team will follow the structural feedback from our initial users to optimize the key features and enhance speeds through infrastructural testing. This rigorous process will lay the foundations for user-centric development with rapid iterations.
                        </p>


                             <h4>Keep Up For More Developments</h4>
                        <p>
                        We’re just getting started on our journey! Make sure you stay up-to-date with our development updates to get sneak peeks at our progress. We also encourage traders and developers to contribute to our initial development by providing feedback. Collectively, we will define the future of crypto trading and implement the most advanced AI-centric platform.     </p>

                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Dev Details Ends Here */}
    </>
  );
}
export default DevDetails2;
