import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import Developmentpost from "../Components/Developmentpost";

function DevUpdates() {
  return (
    <>
      {/* Dev Updates Starts Here */}
      <section className="container-fluid inner-page">
        <Row>
          <Container fluid className="line-bg scroll-anime down">
            <Row>
              <Container>
                <Row>
                  <Col
                    xl={{ span: 8, offset: 2 }}
                    lg={{ span: 10, offset: 1 }}
                    className="text-center"
                  >
                    <h1 className="whitecolor"> Development Updates</h1>
                    <div className="spacer-50" />
                  </Col>
                  <Developmentpost />
                </Row>
              </Container>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Dev Updates Ends Here */}
    </>
  );
}
export default DevUpdates;
