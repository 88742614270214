import React, { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Tab,
  Tabs,
  Form,
  Modal,
  Button,
  Image,
} from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import iconerror from "../../Assets/img/admin-icons/icon-error.png";
import iconpersonaluser from "../../Assets/img/admin-icons/icon-personal-user.png";
import iconlock from "../../Assets/img/admin-icons/icon-lock.png";
import iconsetting from "../../Assets/img/admin-icons/icon-setting.png";
import iconshield from "../../Assets/img/admin-icons/icon-shield.png";
import circleArrow from "../../Assets/img/Icons/icon-arrow-right.png";
import qrcode from "../../Assets/img/q-r-code.png";
import ChatButton from "../../Components/ChatButton";
import UserContest from "../../ContextAPI/UserContest";
import CountrySelect from "../../Components/CountryDropDown";
import {
  CheckFactorVerification,
  Enable2FA_Api,
  UpdateProfileApi,
  UpdateProfilePassword,
  getUserDetail,
} from "../../Api";
import { Notifications } from "../../Components/Toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { PhoneNumberUtil } from "google-libphonenumber";
import { CountrySelector, PhoneInput } from "react-international-phone";
import {
  TextInputRegex,
  colorRed,
  emailInputRegex,
  emailRegex,
  passwordRegex,
} from "../../Constants";
import Select from "react-select";
import countryList from "react-select-country-list";
import { PulseLoader } from "react-spinners";
import OTPInput from "react-otp-input";

function MyProfileDetails({ setShowFooter, setShowNavBar }) {
  const {
    CurrentStage,
    TotalSaleInStage,
    UserDetail,
    SetUserDetail,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
  } = useContext(UserContest);

  const phoneUtil = PhoneNumberUtil.getInstance();
  const [twofaAuthenticationShow, settwofaAuthenticationShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(true);
  const [OTP, setOTP] = useState(false);

  const Countyoptions = useMemo(() => countryList().getData(), []);
  const [ShowOTP, setShowOTP] = useState(false);
  const [FactorLoader, setFactorLoader] = useState(false);

  const [updateProfileLoader, setUpdateProfileLoader] = useState(false);
  const [dialedCode, setDialedCode] = useState();
  const [updateUserDetail, setUpdateUserDetail] = useState(UserDetail);
  const [resetPassword, setResetPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  useEffect(() => {
    setUpdateUserDetail(UserDetail);
    // console.log(UserDetail)
  }, [UserDetail]);
  const UpdateProfile = (setting) => {
    if (!updateProfileLoader) {
      setUpdateProfileLoader(true);
      // console.log(updateUserDetail)
      let temp = { ...updateUserDetail, FaEnabled: false };
      UpdateProfileApi(temp)
        .then(() => {
          getUserDetail()
            .then((detail) => {
              // navigate('/Dashboard')
              SetUserDetail(detail);
            })
            .catch(() => {
              setUpdateProfileLoader(false);
              if (localStorage.getItem("JwtToken")) {
                localStorage.clear();
              }

              // navigate('/')
            });
          if (setting)
            Notifications("success", "Settings Updated Successfully");
          else Notifications("success", "Profile Updated Successfully");
          setUpdateProfileLoader(false);
        })
        .catch(() => {
          setUpdateProfileLoader(false);
        });
    }
  };
  const ChangePassword = () => {
    if (resetPassword.newPassword === resetPassword.confirmPassword) {
      let temp = {
        id: UserDetail?.id,
        password: resetPassword.oldPassword,
        newPassword: resetPassword.newPassword,
      };
      if (!updateProfileLoader) {
        setUpdateProfileLoader(true);
        UpdateProfilePassword(temp)
          .then(() => {
            setUpdateProfileLoader(false);
            Notifications("success", "Password updated successfully");
            getUserDetail()
              .then((detail) => {
                // navigate('/Dashboard')
                SetUserDetail(detail);
              })
              .catch(() => {
                setUpdateProfileLoader(false);
                if (localStorage.getItem("JwtToken")) {
                  localStorage.clear();
                }
                navigate("/");
              });
            setResetPassword({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          })
          .catch(() => {
            setUpdateProfileLoader(false);
            Notifications("error", "Old password does not match");
          });
      }

      // console.log(resetPassword)
    }
  };

  useEffect(() => {
    validatePhone();
  }, [updateUserDetail]);

  const validatePhone = () => {
    try {
      // console.log( )
      const parsedPhone = phoneUtil.parseAndKeepRawInput(
        updateUserDetail?.phonenumber,
        "pk"
      ); // Specify the default region code
      const isValidPhone = phoneUtil.isValidNumber(parsedPhone);
      setIsValid(isValidPhone);
    } catch (error) {
      setIsValid(false);
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "20px",
      // height:'42px',
      // innerHeight:'42px',
      outerHeight: "42px",
      border: "1px solid #ccc",
      boxShadow: "none",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const ConfigureOTP = () => {
    setFactorLoader(true);
    Enable2FA_Api(UserDetail?.id)
      .then(() => {
        setFactorLoader(false);
        setShowOTP(true);
        Notifications("success", "OTP has sent to your Registered Email ");
      })
      .catch(() => {
        setFactorLoader(false);
        Notifications("error", "Some Error has occurred");
      });
  };

  const ValidateTwoFactor = () => {
    setFactorLoader(true);
    const data = {
      user_id: UserDetail?.id,
      otp: OTP,
      twofactor: updateUserDetail?.twofactor ? false : true,
    };
    CheckFactorVerification(data)
      .then(() => {
        setFactorLoader(false);

        Notifications(
          "success",
          "Two Factor Authentication Enabled Successfully"
        );
        setOTP(false);
        setShowOTP(false);
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')
            SetUserDetail(detail);
          })
          .catch(() => {
            setUpdateProfileLoader(false);
            if (localStorage.getItem("JwtToken")) {
              localStorage.clear();
            }
            navigate("/");
          });
      })
      .catch(() => {
        setFactorLoader(false);
        setShowOTP(false);
        Notifications("error", "OTP does not match");
      });
  };
  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl={{ span: 10, offset: 1 }}>
                <div className="text-center">
                  <h1>
                    My <span>Profile Details</span>
                  </h1>
                </div>
                <div className="full-div">
                  {/* <div className="flex-div-sm align-items-center mb-text-left">
                    {" "}
                    <Link
                      className="reg-btn br-radius letter-spacing-1 trans mmt-3"
                      to="/AccountActivities"
                    >
                      Account Activities
                    </Link>
                  </div> */}

                  <div className="spacer-30" />
                  <Tabs
                    onSelect={() => {
                      setUpdateUserDetail(UserDetail);
                    }}
                    defaultActiveKey="PersonalInformation"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                  >
                    <Tab
                      eventKey="PersonalInformation"
                      title={
                        <>
                          <Image
                            src={iconpersonaluser}
                            alt="Personal Imformation"
                          />{" "}
                          Personal Information
                        </>
                      }
                    >
                      <Form>
                        <Row>
                          <Col xl="6" lg="6" md="6">
                            <Form.Group
                              className="mb-2"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Your Name</Form.Label>
                              <Form.Control
                                type="text"
                                onChange={(e) => {
                                  const inputVal = e.target.value;
                                  if (
                                    TextInputRegex.test(inputVal) ||
                                    inputVal === ""
                                  ) {
                                    setUpdateUserDetail((prev) => ({
                                      ...prev, // Correcting the typo here
                                      name: e.target.value, // Correcting the typo here
                                    }));
                                  }
                                }}
                                value={updateUserDetail?.name}
                                autoComplete="new-name"
                                className="input-name"
                                placeholder="Enter Your Name"
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="6" lg="6" md="6">
                            <Form.Group
                              className="mb-2"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Your Email</Form.Label>
                              <Form.Control
                                type="email"
                                value={updateUserDetail?.email}
                                onChange={(e) => {
                                  const inputVal = e.target.value;
                                  if (
                                    emailInputRegex.test(inputVal) ||
                                    inputVal === ""
                                  ) {
                                    setUpdateUserDetail((prev) => ({
                                      ...prev, // Correcting the typo here
                                      email: inputVal, // Correcting the typo here
                                    }));
                                  }
                                }}
                                autoComplete="new-email"
                                className="input-email"
                                placeholder="Enter Your Email"
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="6" lg="6" md="6">
                            <Form.Group
                              className="mb-2"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Phone Number</Form.Label>

                              <PhoneInput
                                style={{ width: "100%" }}
                                defaultCountry={"uk"}
                                value={updateUserDetail?.phonenumber ?? `+1`}
                                onChange={(inputValue, country) => {
                                  setDialedCode(country.country.dialCode);
                                  // console.log(inputValue, country)
                                  setUpdateUserDetail((prev) => ({
                                    ...prev, // Correcting the typo here
                                    phonenumber: inputValue, // Correcting the typo here
                                  }));
                                }}
                              />
                              {updateUserDetail.phonenumber ??
                              "+1" === `+${dialedCode}` ? (
                                <></>
                              ) : (
                                <>
                                  {!isValid && (
                                    <div style={{ color: colorRed }}>
                                      Phone is not valid
                                    </div>
                                  )}
                                </>
                              )}

                              {/* <Form.Control
                                type="text"
                                value={updateUserDetail?.phonenumber}


                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  // Allow only numbers from 0 to 9 and an empty string (for backspace)
                                  if (NumberRegex.test(inputValue)) {
                                    setUpdateUserDetail((prev) => ({
                                      ...prev, // Correcting the typo here
                                      phonenumber: e.target.value, // Correcting the typo here
                                    }));
                                  }
                                }}
                                onKeyPress={(e) => {
                                  // Allow only numbers from 0 to 9 and backspace
                                  const keyCode = e.keyCode || e.which;
                                  const keyValue = String.fromCharCode(keyCode);
                                  if (!NumberRegex.test(keyValue) && keyCode !== 8) {
                                    e.preventDefault();
                                  }
                                }}
                                autoComplete="new-number"
                                placeholder="Enter Your number"
                              /> */}
                            </Form.Group>
                          </Col>
                          <Col xl="6" lg="6" md="6">
                            <Form.Group
                              className="mb-2"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Date of Birth</Form.Label>
                              <Form.Control
                                type="date"
                                value={updateUserDetail?.date_of_birth}
                                onChange={(e) => {
                                  setUpdateUserDetail((prev) => ({
                                    ...prev, // Correcting the typo here
                                    date_of_birth: e.target.value, // Correcting the typo here
                                  }));
                                }}
                                max={new Date().toISOString().split("T")[0]} // Disable future dates in the browser
                                autoComplete="new-number"
                                placeholder="Enter Your number"
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="6" lg="6" md="6">
                            <Form.Group
                              className="input-cntnr"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Select Country</Form.Label>

                              <Select
                                placeholder={"select country"}
                                styles={customStyles}
                                options={Countyoptions}
                                value={Countyoptions.find(
                                  (item) =>
                                    item.label === updateUserDetail.nationality
                                )}
                                onChange={(value) => {
                                  setUpdateUserDetail((prev) => ({
                                    ...prev, // Correcting the typo here
                                    nationality: value.label, // Correcting the typo here
                                  }));
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col xl="12" lg="12" md="12" className="text-center">
                            <div className="spacer-30" />
                            <Link
                              onClick={() => {
                                UpdateProfile();
                              }}
                              className="reg-btn br-radius trans letter-spacing-1"
                              to={void 0}
                            >
                              {updateProfileLoader ? (
                                <PulseLoader size={11} color="white" />
                              ) : (
                                "Update profile"
                              )}
                            </Link>
                          </Col>
                        </Row>
                      </Form>
                    </Tab>
                    <Tab
                      eventKey="MySettings"
                      title={
                        <>
                          <Image src={iconsetting} alt="My Settings" /> My
                          Settings
                        </>
                      }
                    >
                      <div className="spacer-20" />
                      <h5 className="whitecolor font-weight-bolder">
                        Security Settings
                      </h5>
                      <div className="spacer-10" />
                      <Form>
                        {["checkbox"].map((type) => (
                          <div key={`default-${type}`} className="mb-3">
                            <Form.Check // prettier-ignore
                              type={type}
                              checked={updateUserDetail?.activitylog}
                              onChange={(e) => {
                                setUpdateUserDetail((prev) => ({
                                  ...prev, // Correcting the typo here
                                  activitylog: !updateUserDetail?.activitylog, // Correcting the typo here
                                }));
                              }}
                              value={updateUserDetail?.activitylog}
                              className="mb-2"
                              id={`default-${type}`}
                              label="Save my activities log"
                            />
                            <Form.Check
                              checked={updateUserDetail?.emailalert}
                              onChange={(e) => {
                                setUpdateUserDetail((prev) => ({
                                  ...prev, // Correcting the typo here
                                  emailalert: !updateUserDetail?.emailalert, // Correcting the typo here
                                }));
                              }}
                              type={type}
                              id={`default-${type}`}
                              label="Alert me by email in case of unusual activity in my account"
                            />
                          </div>
                        ))}
                      </Form>
                      <div className="spacer-10" />
                      <div className="text-center">
                        <Link
                          onClick={() => {
                            UpdateProfile(true);
                          }}
                          className="reg-btn br-radius trans"
                          to={void 0}
                        >
                          {updateProfileLoader ? (
                            <PulseLoader size={11} color="white" />
                          ) : (
                            " Update settings"
                          )}
                        </Link>
                      </div>
                    </Tab>
                    <Tab
                      eventKey="UpdateLoginCredentials "
                      title={
                        <>
                          <Image
                            src={iconlock}
                            alt="Update Login Credentials"
                          />{" "}
                          Update Login Credentials
                        </>
                      }
                    >
                      <Form>
                        <Row>
                          <Col xl="6" lg="6" md="8">
                            <Form.Group
                              className="mb-2"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Old Password</Form.Label>
                              <div className="log-i-cntnr">
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  value={resetPassword.oldPassword}
                                  onChange={(e) => {
                                    setResetPassword((prev) => ({
                                      ...prev, // Correcting the typo here
                                      oldPassword: e.target.value, // Correcting the typo here
                                    }));
                                  }}
                                  autoComplete="new-name"
                                  className="input-password"
                                  placeholder="Enter old password"
                                />
                                {!showPassword ? (
                                  <FaEyeSlash
                                    className="FaEyeSecond"
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  />
                                ) : (
                                  <FaEye
                                    className="FaEyeSecond"
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  />
                                )}
                              </div>
                              {/* className="log-i-cntnr" */}
                            </Form.Group>
                          </Col>
                          <Col xl="6" lg="6" md="6">
                            <Form.Group
                              className="mb-2"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>New Password</Form.Label>
                              <div className="log-i-cntnr">
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  value={resetPassword.newPassword}
                                  onChange={(e) => {
                                    setResetPassword((prev) => ({
                                      ...prev, // Correcting the typo here
                                      newPassword: e.target.value, // Correcting the typo here
                                    }));
                                  }}
                                  autoComplete={false}
                                  className="input-password"
                                  placeholder="Enter new Password"
                                />
                                {!showPassword ? (
                                  <FaEyeSlash
                                    className="FaEyeSecond"
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  />
                                ) : (
                                  <FaEye
                                    className="FaEyeSecond"
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  />
                                )}
                              </div>
                            </Form.Group>
                          </Col>
                          <Col xl="6" lg="6" md="6">
                            <Form.Group
                              className="mb-2"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Label>Confirm Password</Form.Label>
                              <div className="log-i-cntnr">
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  value={resetPassword.confirmPassword}
                                  onChange={(e) => {
                                    setResetPassword((prev) => ({
                                      ...prev, // Correcting the typo here
                                      confirmPassword: e.target.value, // Correcting the typo here
                                    }));
                                  }}
                                  autoComplete={false}
                                  className="input-password"
                                  placeholder="Enter comfirm password"
                                />
                                {!showPassword ? (
                                  <FaEyeSlash
                                    className="FaEyeSecond"
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  />
                                ) : (
                                  <FaEye
                                    className="FaEyeSecond"
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  />
                                )}
                              </div>
                              {resetPassword.newPassword !==
                                resetPassword.confirmPassword &&
                                resetPassword.confirmPassword &&
                                resetPassword.newPassword && (
                                  <p style={{ color: colorRed }}>
                                    Password does not match.
                                  </p>
                                )}
                            </Form.Group>
                          </Col>
                          {/* <Col xl="12" lg="12" md="12">
                            <div className="spacer-20" />
                            <p className="m-0 d-flex align-items-start mb-2">
                              <img
                                className="mr-1"
                                src={iconerror}
                                alt="icon-error"
                              />{" "}
                              Password should be a minimum of 6 digits and
                              include lower and uppercase letter.
                            </p>
                            <p className="d-flex align-items-start">
                              <img
                                className="mr-1"
                                src={iconerror}
                                alt="icon-error"
                              />{" "}
                              Your password will only change after your
                              confirmation by email.
                            </p>
                          </Col> */}
                          <Col xl="12" lg="12" md="12" className="text-center">
                            <div className="spacer-30" />
                            <Link
                              onClick={() => {
                                ChangePassword();
                              }}
                              className="reg-btn br-radius trans"
                              to={void 0}
                            >
                              {updateProfileLoader ? (
                                <PulseLoader size={11} color="white" />
                              ) : (
                                "  Update Password"
                              )}
                            </Link>
                          </Col>
                        </Row>
                      </Form>
                    </Tab>
                  </Tabs>
                </div>
                <div className="text-center">
                  <h4 className="font-weight-bolder whitecolor">
                    <Image src={iconshield} alt="Icon Shield" /> Two-Factor
                    Verification
                  </h4>
                  <div className="d-flex align-items-center justify-content-center m-j-c">
                    {/* <p className="font-weight-bold m-0 ">Current Status:</p> */}
                    {updateUserDetail?.twofactor ? (
                      <span className="reject-span active letter-spacing-1 ml-3">
                        <span className="active">Active</span>
                      </span>
                    ) : (
                      <span
                        className="reject-span active letter-spacing-1 ml-3"
                        style={{ backgroundColor: "#EC5760" }}
                      >
                        <span
                          className="active"
                          style={{ backgroundColor: "#EC5760" }}
                        >
                          Disabled
                        </span>
                      </span>
                    )}
                  </div>
                  <div className="spacer-20" />

                  <p className="greycolor">
                    With 2-Step Verification, also called two-factor
                    authentication, you can add an extra layer of security to
                    your account in addition to your password.
                  </p>
                  <div className="text-center">
                    <Button
                      className="reg-btn br-radius arrow-btn"
                      to="javascript:void(0);"
                      onClick={() => {
                        setOTP(false);
                        ConfigureOTP();
                      }}
                    >
                      <Image src={circleArrow} alt="Arrow" />
                      {FactorLoader ? (
                        <PulseLoader size={11} color="white" />
                      ) : updateUserDetail?.twofactor ? (
                        "Disable 2FA"
                      ) : (
                        "Enable 2FA"
                      )}{" "}
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>

      {/* 2Fa Authentication Modal Starts Here */}
      <Modal
        size="lg"
        show={twofaAuthenticationShow}
        centered
        onHide={() => settwofaAuthenticationShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => settwofaAuthenticationShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <div className="text-center">
            {" "}
            <h3>Enable 2FA Authentication</h3>
          </div>
          <div className="spacer-20" />
          <p className="m-0 greycolor">
            <b className="whitecolor">Step 1:</b> Install this app from{" "}
            <Link className="simple-link purple">Google Play</Link> store or
            <Link className="simple-link purple">App Store.</Link>
          </p>
          <p className="mt-2 greycolor">
            <b className="whitecolor">Step 2:</b> Scan the below QR code by your
            Google Authenticator app, or you can add account manually.
          </p>
          <div className="spacer-20" />
          <h6 className="f-18">Manually add Account:</h6>
          <p className="m-0">
            Account Name:
            <b className="color"> IntelMarket</b>
          </p>
          <p>
            Key:
            <b className="color"> 6PSGTIQRT7S2ZRZI</b>
          </p>
          <div className="spacer-10" />
          <div className="flex-div-md align-items-center">
            <div>
              <img src={qrcode} alt="QR Code" />
            </div>
            <div className="pl-lg-4 w-100">
              <div className="spacer-10" />
              <Form>
                <Form.Group
                  className="mb-4"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Enter Google Authenticator Code</Form.Label>
                  <Form.Control
                    type="email"
                    autoComplete="new-email"
                    className="input-email"
                    placeholder="Enter Google Authenticator Code"
                  />
                </Form.Group>
              </Form>
              <Button className="reg-btn br-radius arrow-btn">
              <Image src={circleArrow} alt="Arrow" /> Enable 2FA 
              </Button>
            </div>
          </div>
          <div className="spacer-40" />
          <p className="m-0 redcolor ">
            <b className="notranslate">Note:</b> If you lose your phone or
            delete Google Authenticator, you will no longer be able to log in to
            your account.
          </p>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={ShowOTP}
        centered
        onHide={() => setShowOTP(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button className="close-btn" onClick={() => setShowOTP(false)}>
            <i className="fa fa-close"></i>
          </Button>
          <p style={{ textAlign: "center" }}>
            OTP has sent to your Registered Email
          </p>
          <div className="otpinput">
            <OTPInput
              value={OTP}
              onChange={setOTP}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <br />
          </div>
          <div className="text-center">
            <a
              className="text-center"
              onClick={() => ConfigureOTP()}
              style={{ cursor: "pointer" }}
            >
              Resend OTP
            </a>
            <br />
            <Button
              onClick={() => {
                ValidateTwoFactor();
              }}
              className="reg-btn br-radius letter-spacing-1"
            >
              {FactorLoader ? (
                <PulseLoader size={11} color="white" />
              ) : (
                "  Validate OTP"
              )}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {/* 2Fa Authentication Modal Ends Here */}
    </>
  );
}
export default MyProfileDetails;
