import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Image } from "react-bootstrap";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import AiPowered from "../Assets/img/Home/Ai-Powered.png";
import { PresaleButton, PresalesButton } from "./Buttons";
import { useAccount, useDisconnect } from "wagmi";
function PoweringNext({ setSignupShow }) {
  const { address, isConnecting, isDisconnected } = useAccount();
  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);
  return (
    <>
      <Row>
        <Container className="zindex">
          <Row
            onClick={() => {
              setSignupShow(true);
            }}
          >
            <Col
              xl={{ span: 6, order: 1 }}
              lg={{ span: 5, order: 1 }}
              md={{ span: 6, order: 1 }}
              sm={{ span: 12, order: 2 }}
              xs={{ span: 12, order: 2 }}
              className="scroll-anime left"
            >
              <img src={AiPowered} alt="Ai" />
            </Col>
            <Col
              xl={{ span: 6, order: 2 }}
              lg={{ span: 7, order: 2 }}
              md={{ span: 6, order: 2 }}
              sm={{ span: 12, order: 1 }}
              xs={{ span: 12, order: 1 }}
              className="scroll-anime right"
            >
              <div className="spacer-50 web-view" />
              <div className="spacer-50 web-view" />
              <h2 className="whitecolor">
              Automated Trading Made Simple - The INTL Advantage

              </h2>

              <p className="lightcolor" style={{ lineHeight: "32px" }}>
              Experience the efficiency of advanced trading robots monitoring data on the blockchain in real time. IntelMarkets is the first platform to deliver unparalleled trading performance with AI implementation on the blockchain layer. Get insights from over 350,000+ data points and leverage our advanced infrastructure that is designed for security and convenience. 

              </p>
              <div className="spacer-10" />
              <Link
                className="reg-btn br-radius mb-1 arrow-btn"
                onClick={() => {
                  setSignupShow(true);
                }}
                to={void 0}
              >
                <Image src={circleArrow} alt="Arrow" /> Join Presale
              </Link>
            </Col>
          </Row>
        </Container>
      </Row>
    </>
  );
}
export default PoweringNext;
