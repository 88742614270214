import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import card1 from "../Assets/img/Home/card-1.jpg";
import card2 from "../Assets/img/Home/card-2.jpg";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import { Col, Image } from "react-bootstrap";
function Developmentpost() {
  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);
  return (
    <>
      <Col xl="12" lg="12" md="12" sm="12">
        <div className="Development-post-main scroll-anime left">
          <div className="Development-post">
            <Link to="/dev-updates-1" className="img-pnl">
              <img src={card1} alt="Desktop App" />
            </Link>
            <div className="txt-pnl">
              <h5>Intel Markets - Development Update 1 </h5>
              <p>
                We’re excited to reveal the first development update for our
                Intel Markets platform. These updates will be essential to our
                goal of unveiling the future of crypto trading. Intel Markets is
                the world’s first AI-powered smart trading platform powered by
                trading bots and smart algorithms.{" "}
              </p>
              <div className="btn-bottom-container">
                <Link
                  to="/dev-updates-1"
                  className="reg-btn br-radius arrow-btn d-inline-flex"
                >
                  <Image src={circleArrow} alt="Arrow" />
                  Read More
                </Link>
              </div>
              {/* <span>Sep 24, 2024</span> */}
            </div>
          </div>
          <div className="Development-post scroll-anime right">
            <Link to="/dev-updates-2" className="img-pnl">
              <img src={card2} alt="Desktop App" />
            </Link>
            <div className="txt-pnl">
              <h5>Intel Markets - Development Update 2 </h5>
              <p>
                We’ve made significant progress this week in the development and
                expansion of Intel Markets. The key focus area was the
                initiation of the exchange development, which included
                fundamental groundwork for our main features.{" "}
              </p>
              <div className="btn-bottom-container">
                <Link
                  to="/dev-updates-2"
                  className="reg-btn br-radius arrow-btn d-inline-flex"
                >
                  <Image src={circleArrow} alt="Arrow" />
                  Read More
                </Link>
              </div>
              {/* <span>Sep 24, 2024</span> */}
            </div>
          </div>
        </div>
      </Col>
    </> 
  );
}
export default Developmentpost;
