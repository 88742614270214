import { ToastContainer } from 'react-toastify';
import Layout from './Layout';
import 'react-toastify/dist/ReactToastify.css'
import { useContext, useEffect } from 'react';
import { GetAllStageTransactions, GetCurrentStage, GetCurrentStageTransactions } from './Api';
import UserContest from './ContextAPI/UserContest';
import GlobalErrorBoundary from './GlobalHandler';
import { walletConnectProvider, EIP6963Connector } from '@web3modal/wagmi'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { FE_URL, NoStageActive, WalletIcon, projectId } from './Constants';
import { WagmiConfig, configureChains, createConfig } from 'wagmi'
import { arbitrum,  mainnet, polygon, optimism } from 'viem/chains'
import { publicProvider } from 'wagmi/providers/public'

import { CoinbaseWalletConnector  } from 'wagmi/connectors/coinbaseWallet'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'


function App() {
  const { SetTotalSaleInStage,
    SetCurrentStage, SetOverAllSaleToken } = useContext(UserContest);

  useEffect(() => {
    GetCurrentStage().then((stage) => {
      //  console.log(stage,'stage' )
      SetCurrentStage(stage)
      if(!stage?.id)
      {
        SetCurrentStage(NoStageActive)
      }
      GetCurrentStageTransactions(stage.id).then((data) => {
        SetTotalSaleInStage(data.data.total_tokens_purchased
        )
      }).catch(() => {


      })


    }).catch(() => {
      SetCurrentStage(NoStageActive)

    })
    GetAllStageTransactions().then((data) => {
      SetOverAllSaleToken(data.data.total_tokens_purchased)
    }).catch(() => {

    })
  }, [])



  // 2. Create wagmiConfig
  const { chains, publicClient } = configureChains(
    [mainnet, polygon, optimism,arbitrum],
    [walletConnectProvider({ projectId }), publicProvider()]
  )
  
  const metadata = {
    name: 'IntelMarket',
    description: 'A decentralized algorithmic crypto trading platform',
    url: FE_URL,
    icons: [WalletIcon]
  }
  
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: [
      new WalletConnectConnector({ chains, options: { projectId, showQrModal: false, metadata } }),
      new EIP6963Connector({ chains }),
      new InjectedConnector({ chains, options: { shimDisconnect: true } }),
      new CoinbaseWalletConnector({ chains, options: { appName: metadata.name } })
    ],
    publicClient
  })
  
  // 3. Create modal
  createWeb3Modal({ wagmiConfig, projectId, chains,
    themeMode: 'dark',
    themeVariables: {
      '--w3m-accent': '#DBDB02',
      '--wui-color-accent-100' : 'black',


    }
  })

  return (
    <>
      <GlobalErrorBoundary >
      <WagmiConfig
        
      config={wagmiConfig}>
          <Layout />
        </WagmiConfig>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme={'light'}
        />
      </GlobalErrorBoundary>
    </>
  )
}
export default App;