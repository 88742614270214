import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
function TermsConditions() {


  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])   
  return (
    <>
      {/* Terms And Conditions Starts Here */}
      <section className="terms-policy container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h1>Terms and Conditions</h1>
              </Col>
              <Col xl="12" lg="12" md="12">
                <h3> Terms of Use</h3>
                <p>
                  {" "}
                  These Terms of Use outline the conditions for your access to
                  and use of all content, products, and services available
                  through our website (the "Service"), operated by us ("we,"
                  "us," or "our"). Your use of our services is contingent upon
                  your acceptance of and compliance with these terms and
                  conditions and any other operating rules and policies that we
                  may publish periodically. Please read the agreement carefully
                  before using our services. By accessing or using any part of
                  our services, you agree to be bound by these terms. If you do
                  not agree to any portion of these terms, you are not permitted
                  to access or use our services.
                </p>
                <h3> Copyright</h3>
                <p>
                  {" "}
                  All text and images on this platform are the property of
                  IntelMarkets. Reproduction or distribution of this material
                  without prior permission from IntelMarkets is prohibited.
                  Approved use of content must adhere to the following
                  conditions: the source must be credited as IntelMarkets, and
                  this acknowledgment must be included in all forms of
                  distribution.
                </p>
                <h3> E-mail Communication</h3>
                <p>
                  {" "}
                  You may choose to communicate with us via e-mail. Please be
                  aware that internet e-mail security is uncertain. By sending
                  confidential or sensitive e-mail messages that are
                  unencrypted, you accept the risks associated with such
                  uncertainty and the possibility of a breach of confidentiality
                  or privacy over the internet.
                </p>
                <h3> No Warranty</h3>
                <p>
                  {" "}
                  The information provided by IntelMarkets is intended as a
                  service or promotion to its users, subscribers, customers, and
                  others. It does not constitute legal advice. While we strive
                  to provide quality information, we do not guarantee the
                  results obtained from using this information and disclaim all
                  warranties, express or implied, including but not limited to,
                  warranties of performance for a particular purpose.
                </p>
                <h3> No Liability</h3>
                <p>
                  {" "}
                  IntelMarkets is not liable for any damages or costs, direct or
                  indirect, consequential, incidental, or otherwise, arising
                  from the use of the information contained in our newsletters.
                </p>
                <h3> Changes</h3>
                <p>
                  {" "}
                  We reserve the right to make changes to this information at
                  any time without prior notice. We are under no obligation to
                  update the information contained in our newsletters.
                </p>
                <h3> Intellectual Property</h3>
                <p>
                  {" "}
                  This agreement does not transfer any of our or any third
                  party's intellectual property to you. All rights, title, and
                  interest in and to such property will remain solely with us
                  and our licensors.
                </p>
                <h3> Third-Party Services</h3>
                <p>
                  {" "}
                  While using our services, you may utilize third-party
                  services, products, software, embeds, or applications
                  developed by a third party ("Third-Party Services"). Your use
                  of any Third-Party Services is at your own risk, and we are
                  not responsible or liable for any third-party websites or
                  services. You acknowledge and agree that we are not
                  responsible or liable for any damage or loss caused or alleged
                  to be caused by or in connection with the use of any such
                  content, goods, or services available on or through any such
                  websites or services.
                </p>
                <h3> Accounts</h3>
                <p>
                  {" "}
                  If using any part of our services requires an account, you
                  agree to provide us with accurate and complete information
                  during registration. You are responsible for any activity that
                  occurs under your account, maintaining the security of your
                  account, and keeping your password secure. You must notify us
                  immediately of any unauthorized use of your account or any
                  other breaches of security.
                </p>
                <h3> Termination</h3>
                <p>
                  {" "}
                  We may terminate or suspend your access to all or any part of
                  our services at any time, with or without cause, with or
                  without notice, effective immediately. If you wish to
                  terminate the agreement or your account, you may simply
                  discontinue using our services. All provisions of the
                  agreement that by their nature should survive termination
                  shall survive termination, including, but not limited to,
                  ownership provisions, warranty disclaimers, indemnity, and
                  limitations of liability.
                </p>
                <h3> Disclaimer</h3>
                <p>
                  {" "}
                  Our services are provided on an "AS IS" and "AS AVAILABLE"
                  basis. We and our suppliers and licensors disclaim all
                  warranties, express or implied, including but not limited to,
                  warranties of merchantability, fitness for a particular
                  purpose, and non-infringement. Neither we nor our suppliers
                  and licensors make any warranty that our services will be
                  error-free or that access will be continuous or uninterrupted.
                  You understand that you download from, or otherwise obtain
                  content or services through, our services at your own
                  discretion and risk.
                </p>
                <h3> Changes</h3>
                <p>
                  {" "}
                  IntelMarkets reserves the right, at our sole discretion, to
                  modify or replace these terms at any time. If we make material
                  changes, we will notify you by posting a notice on our website
                  or by sending you an email or other communication before the
                  changes take effect. The notice will designate a reasonable
                  period after which the new terms will take effect. If you
                  disagree with our changes, you should stop using our services
                  within the designated notice period or once the changes become
                  effective. Your continued use of our services will be subject
                  to the new terms.
                </p>
                <h3> Refund Policy</h3>
                <p>
                  {" "}
                  Refunds are only applicable to presale purchases within 24
                  hours of the purchase. If you wish to request a refund, please
                  contact us within this timeframe.
                </p>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Terms And Conditions Ends Here */}
    </>
  );
}
export default TermsConditions;
