import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import speed1 from "../Assets/img/Home/speed-1.png";
import speed2 from "../Assets/img/Home/speed-2.png";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

function SpeedPost() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 991, min: 767 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        <div>
          <div className="speed-post">
            <div className="text-panel">
              <h3>Experience The AI-First Blockchain - IntelMarkets 
              </h3>
              <p>
              Delivering exceptional on-chain settlement with native artificial intelligence. 

              </p>

              <Link className="reg-btn br-radius arrow-btn d-inline-flex">
                <Image src={circleArrow} alt="Arrow" /> Buy $INTL Now!
              </Link>
            </div>

            <div className="img-pnl">
              <Image src={speed1} alt="Arrow" />
            </div>
          </div>
        </div>
        <div>
          <div className="speed-post">
            <div className="text-panel">
              <h3>Experience The AI-First Blockchain - IntelMarkets 
              </h3>
              <p>
              Delivering exceptional on-chain settlement with native artificial intelligence. 

              </p>
              <Link className="reg-btn br-radius arrow-btn d-inline-flex">
                <Image src={circleArrow} alt="Arrow" /> Buy $INTL Now!
              </Link>
            </div>
            <div className="img-pnl">
              <Image src={speed2} alt="Arrow" />
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
}
export default SpeedPost;
