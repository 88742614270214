import React from "react";
import { Row, Container, Col,  Image } from "react-bootstrap";
import bgimg1 from "../Assets/img/Giveaway/bg.png";
import bgimg from "../Assets/img/Bg/presale-bg.png";
import giveaway from "../Assets/img/Giveaway/giveaway.png";
import {  PresaleButtons } from "../Components/Buttons";
function Giveaway({ setSignupShow }) {
  return (
    <>
      {/* Terms And Conditions Starts Here */}
      <section className="giveaway-pnl container-fluid">
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${bgimg1})` }}
          />
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-panel">
                <h1>
                  <span>Win $1</span> With Intel Markets
                </h1>
                <div className="spacer-40"/>
                <p>
                  Participate in{" "}
                  <span className="yellow-color">
                    Intel Markets $1,000,000 Giveaway
                  </span>{" "}
                  for a chance to be one of the{" "}
                  <span className="yellow-color">10 LUCKY</span> winners who
                  will receive{" "}
                  <span className="yellow-color">
                    $100,000 worth of $INTL each!
                  </span>{" "}
                  Increase your chances of winning by completing all tasks and
                  unlocking bonus entries.
                  <br />
                  Act now to seize this opportunity by sharing and
                  participating.
                </p>
                <div className="spacer-10"/>

                <p>
                  <b>PLEASE NOTE:</b> To qualify, it is essential to have a
                  minimum participation of $100 in the Intel Markets Presale.
                </p>
                <div className="spacer-30"/>
                <PresaleButtons setSignupShow={setSignupShow} />
              </Col>
              <Col xl="12" lg="12" md="12" className="text-center">
                {/* <EntiresPanel setSignupShow={setSignupShow} /> */}
                <Image src={giveaway} alt="Enries" />
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Terms And Conditions Ends Here */}
      <Container className="join-presale-now text-center" fluid>
        <div
          className="bg-layer"
          style={{ backgroundImage: `url(${bgimg})` }}
          />
        <Row>
          <Container>
            <Row>
              <Col xl={{ span: 8, offset: 2 }}>
                <h1>
                  Join <span>Presale</span> Now!
                </h1>
                <p>
                  Join the Elite Ranks today.
                  <br /> Trade Smarter, Trade Better, Trade with Intel Markets.
                </p>
                <div className="mt-4 mb-1">
                  <PresaleButtons setSignupShow={setSignupShow} />
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>>
    </>
  );
}
export default Giveaway;
