import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Form,
  Button,
  Modal,
  Image,
} from "react-bootstrap";
import NavBarDash from "../../Components/NavBarDash";
import iconcopy from "../../Assets/img/admin-icons/icon-copy.png";
import icontether from "../../Assets/img/admin-icons/icon-tether.png";
import iconi from "../../Assets/img/admin-icons/icon-i.png";
import iconired from "../../Assets/img/Icons/icon-ii.png";
import iconlogo from "../../Assets/img/admin-icons/icon-logo.png";
import circleArrow from "../../Assets/img/Icons/icon-arrow-right.png";
import ChatButton from "../../Components/ChatButton";
import UserContest from "../../ContextAPI/UserContest";
import {
  UpdateProfileApi,
  WalletaddressChanage,
  WalletaddressRequest,
  getBonusTokenOfUser,
  getPurchaseTokenOfUser,
  getReferralTokenOfUser,
  getTotalTokenOfUser,
  getUserDetail,
} from "../../Api";
import { Notifications } from "../../Components/Toast";
import {
  CopyToClipboardButton,
  validateEthereumAddress,
} from "../../Constants/utils";
import { ReferralLink, colorRed } from "../../Constants";
import OtpInput from "react-otp-input";
function MyToken({ setShowFooter, setShowNavBar }) {
  const [totalToken, setTotalToken] = useState();
  const [referralToken, setreferralToken] = useState();
  const [bonusToken, setbonusToken] = useState();
  const [purchaseToken, setPurchaseToken] = useState();
  const [wallerAddress, setWalletAddsress] = useState();
  const navigate = useNavigate();
  const {
    CurrentStage,
    TotalSaleInStage,
    UserDetail,
    SetUserDetail,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
  } = useContext(UserContest);
  const [walletaddressShow, setwalletaddressShow] = useState(false);
  const [ShowOTP, setShowOTP] = useState(false);
  const [OTP, setOTP] = useState();

  useEffect(() => {
    setShowFooter(false);
    setShowNavBar(false);
  }, [setShowFooter, setShowNavBar]);
  useEffect(() => {
    getTotalTokenOfUser(UserDetail?.id)
      .then((data) => {
        setTotalToken(data.data.total_tokens_purchased);
      })
      .catch(() => {});
    getPurchaseTokenOfUser(UserDetail?.id)
      .then((data) => {
        setPurchaseToken(data.data.total_tokens_purchased);
      })
      .catch(() => {});
    getBonusTokenOfUser(UserDetail?.id)
      .then((data) => {
        setbonusToken(data.data.total_tokens_purchased);
      })
      .catch(() => {});
    getReferralTokenOfUser(UserDetail?.id)
      .then((data) => {
        setreferralToken(data.data.total_tokens_purchased);
      })
      .catch(() => {});
  }, [UserDetail]);
  const UpdateWalletAddress = () => {
    UserDetail.wallet_address = wallerAddress;
    UpdateProfileApi(UserDetail)
      .then(() => {
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem("JwtToken")) {
              localStorage.clear();
            }

            navigate("/");
          });
        Notifications("success", "Wallet Added Successfully");
      })
      .catch(() => {});
  };

  const Walletaddresschanage = () => {
    WalletaddressChanage(UserDetail?.id)
      .then(() => {
        Notifications("success", "OTP has sent to your Mail");
      })
      .catch(() => {});
  };
  const Walletaddressrequest = () => {
    const data = {
      user_id: UserDetail?.id,
      otp: OTP,
      wallet_address: wallerAddress,
    };
    WalletaddressRequest(data)
      .then(() => {
        getUserDetail()
          .then((detail) => {
            // navigate('/Dashboard')

            SetUserDetail(detail);
          })
          .catch(() => {
            if (localStorage.getItem("JwtToken")) {
              localStorage.clear();
            }
            navigate("/");
          });
        setShowOTP(false);
        setOTP("");
        setwalletaddressShow(false);
        Notifications("success", "Wallet Added Successfully");
      })
      .catch(() => {
        // Notifications("success", "Entered OTP is incorrect");
      });
  };

  return (
    <>
      <NavBarDash />
      <ChatButton />
      <section className="admin-panel container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" className="text-center">
                <h1>
                  My <span>$INTL Token</span>
                </h1>
                <div className="spacer-20" />
              </Col>
              <div className="flex-contr lg">
                <div className="blue-post w-4 mb-3">
                  <Row>
                    <Col xl="12" lg="12" md="12">
                      <div className="token-equivalent-pnl">
                        <div>
                          <h4 className="font-weight-bold  color text-center">
                            Total Token Amount
                          </h4>
                          <div className="spacer-10" />
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              className="mr-2"
                              src={iconlogo}
                              alt="Icon Logo"
                            />{" "}
                            <h3 className="font-weight-bold ">
                              {parseFloat(totalToken??0).toFixed(2) ?? 0}
                            </h3>
                            <h4 className="font-weight-normal yellow-color ml-2">
                              $INTL
                            </h4>
                          </div>
                        </div>
                        <div>
                          <div className="spacer-20" />
                          <h4 className="font-weight-bold  color text-center">
                            Equivalent to:{" "}
                          </h4>
                          <div className="d-flex justify-content-center align-items-center">
                            <img
                              className="mr-2"
                              src={icontether}
                              alt="Icon Logo"
                            />{" "}
                            <h3 className="font-weight-bold ">
                            {  parseFloat( CurrentStage?.price_of_token * totalToken ?? 0 ).toFixed(2) }
                       
                            </h3>
                            <h4 className="font-weight-normal yellow-color ml-2">
                              USD
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="spacer-10" />
                    </Col>
                    <Col xl="12" lg="12" md="12">
                      <div className="small-bg-panel">
                        <ul className="my-dotted-list w-100">
                          <li className="w-100">
                            <p className="notranslate m-0 font-weight-normal greycolor">
                              Total Token Amount
                            </p>
                            <span />
                            <p>{parseFloat(totalToken??0).toFixed(2) ?? 0}</p>
                          </li>
                          <li className="w-100">
                            <p>Purchased Token</p>
                            <span />
                            <p>{parseFloat(purchaseToken??0).toFixed(2) ?? 0}</p>
                          </li>
                          <li className="w-100">
                            <p>Referral Token</p>
                            <span />
                            <p>{parseFloat(referralToken??0).toFixed(2) ?? 0}</p>
                          </li>
                          <li className="w-100">
                            <p>Bonus Token</p>
                            <span />
                            <p>{parseFloat(bonusToken??0).toFixed(2) ?? 0}</p>
                          </li>
                        </ul>
                      </div>
                    </Col>
                    <Col xl="12" lg="12" md="12" className="text-center">
                      <div className="spacer-20" />
                      <Link
                        className="reg-btn br-radius arrow-btn"
                        to="/PurchaseToken"
                      >
                       <Image src={circleArrow} alt="Arrow" /> Buy more tokens 
                      </Link>
                    </Col>
                  </Row>
                </div>
                <div className="w-8">
                  {/* Blue Post */}
                  <div className="blue-post mb-3">
                    <h4 className="font-weight-bold  color">
                      My Wallet Address
                    </h4>
                    <p className="greycolor">
                      Add or update your Ethereum wallet address to receive your
                      $INTL tokens.
                    </p>

                    <div className="d-flex-sm align-items-start">
                      <p className="font-weight-bold greycolor mr-4">
                        Receiving Wallet
                      </p>
                      {UserDetail?.wallet_address === "null" ||
                      UserDetail?.wallet_address === null ? (
                        <Button
                          className="simple-link purple font-qs"
                          onClick={() => setwalletaddressShow(true)}
                        >
                          Add
                        </Button>
                      ) : (
                        <>
                          <p
                            onClick={() =>
                              CopyToClipboardButton(
                                UserDetail?.wallet_address,
                                "Address Copied Successfully"
                              )
                            }
                            className="color m-0 cursor"
                          >{`${UserDetail?.wallet_address?.slice(
                            0,
                            5
                          )}...${UserDetail?.wallet_address?.slice(-5)}`}</p>

                          <span className="tooltip-span ml-2 mr-2">
                            <img src={iconi} alt="Icon i" />
                            <span>{UserDetail?.wallet_address}</span>
                          </span>
                          {/* </div> */}
                          <Button
                            className="simple-link purple font-qs"
                            onClick={() => {
                              setwalletaddressShow(true);
                              setWalletAddsress("");
                            }}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  {/* Blue Post */}
                  {/* Blue Post */}
                  <div className="blue-post mb-3">
                    <div className="spacer-20" />
                    <h4 className="font-weight-bold color">
                      Total Token Amount
                    </h4>
                    <div className="d-flex align-items-center">
                      <h3 className="font-qs font-weight-bold m-0">
                        <img className="mr-1" src={iconlogo} alt="Icon Logo" />{" "}
                        {parseFloat(totalToken??0).toFixed(2) ?? 0}
                      </h3>
                      <h4 className="ml-3 yellow-color m-0">$INTL</h4>
                    </div>
                    <div className="spacer-20" />
                  </div>
                  {/* Blue Post */}
                  {/* Blue Post */}
                  <div className="blue-post mb-3">
                    <h4 className="font-weight-bold color">
                      Refer a Friend
                    </h4>
                    <div className="spacer-10" />
                    <p className="greycolor m-0">
                      Refer a friend and earn $INTL tokens.
                    </p>
                    <div className="spacer-10" />
                    <p className="greycolor font-weight-bold m-0">
                      My referral link:
                    </p>
                    <p className=" notranslate m-0">
                      {ReferralLink}
                      {UserDetail?.referral_link}{" "}
                      <Link
                        className="ml-3 color"
                        onClick={() => {
                          CopyToClipboardButton(
                            ReferralLink + UserDetail?.referral_link,
                            "Link Copied Successfully"
                          );
                        }}
                      >
                        Copy Link <img src={iconcopy} alt="Copy icon" />
                      </Link>
                    </p>
                  </div>
                  {/* Blue Post */}
                </div>
              </div>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Edit  Wallet Address Modal Starts Here */}
      <Modal
        size="lg"
        show={walletaddressShow}
        centered
        onHide={() => setwalletaddressShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button
            className="close-btn"
            onClick={() => setwalletaddressShow(false)}
          >
            <i className="fa fa-close"></i>
          </Button>
          <div className="text-center">
            <h3 className="text-bold">
              Wallet <span className="color">Address</span>
            </h3>
          </div>
          <p className="greycolor">
            To receive your <b>$INTL</b>, you will need to enter your Ethereum
            wallet address in the box below. You can claim your <b>$INTL</b>{" "}
            when the presale ends.
          </p>
          <div className="spacer-20" />

          <Form>
            <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <i>Enter Wallet Address</i>
              </Form.Label>
              <Form.Control
                type="text"
                value={wallerAddress}
                onChange={(e) => {
                  setWalletAddsress(e.target.value);
                }}
                autoComplete="new-email"
                className="input-email border-rad-0"
                placeholder="Enter Your Address"
              />
              {wallerAddress && !validateEthereumAddress(wallerAddress) && (
                <p style={{ color: "red" }}>Please enter a valid Address</p>
              )}
            </Form.Group>
          </Form>
          <div className="d-flex align-items-start mb-2">
            <img className="mr-2" src={iconired} alt="Icon wallet" />
            <p className="greycolor m-0">
              Address should be <b>ERC-20</b> compliant.
            </p>
          </div>
          <div className="d-flex align-items-start">
            <img className="mr-2" src={iconired} alt="Icon i" />
            <p className="greycolor">
              <b>Do NOT</b> use your exchange wallet address or a wallet for
              which you do not have a private key. If you do, you will not
              receive your Tokens and could lose your funds.
            </p>
          </div>
          <div className="spacer-30" />
          <div className="text-center">
            <Button
              disabled={!validateEthereumAddress(wallerAddress)}
              onClick={() => {
                Walletaddressrequest();
                //  setShowOTP(true)
                //  setOTP(false)
              }}
              className="reg-btn br-radius arrow-btn letter-spacing-1"
            >
              <Image src={circleArrow} alt="Arrow" /> Add Wallet address 
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={ShowOTP}
        centered
        onHide={() => setShowOTP(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Body className="dashboard-modal">
          <Button className="close-btn" onClick={() => setShowOTP(false)}>
            <i className="fa fa-close"></i>
          </Button>
          <h4 style={{ textAlign: "center" }}>
            <span className="color">OTP</span> has sent to your{" "}
            <span className="color">Registered</span> Email
          </h4>
          <div className="spacer-10"/>
          <div className="otpinput">
            <OtpInput
              value={OTP}
              onChange={setOTP}
              numInputs={6}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
            <br />
          </div>
          <div className="text-center">
            <a
              className="text-center simple-link"
              onClick={() => Walletaddresschanage()}
              style={{ cursor: "pointer" }}
            >
              Resend OTP
            </a>
            <div className="spacer-20" />
            <Button
              onClick={() => {
                Walletaddressrequest();
              }}
              className="reg-btn br-radius arrow-btn"
            >
             <Image src={circleArrow} alt="Arrow" /> Add Wallet address 
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit Wallet Address Modal Ends Here */}
    </>
  );
}
export default MyToken;
