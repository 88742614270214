import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import { Image } from "react-bootstrap";
export const PresaleNowButton = ({ setSignupShow = () => {} }) => (
  <>
    <div className="mt-4 mb-1">
      <Link
        onClick={() => {
          setSignupShow(true);
        }}
        className="reg-btn br-radius"
        to={void 0}
      >
        Join Presale NOW
      </Link>
      {/* <Button className="reg-btn br-radius">
                    Join Presale NOW
                  </Button> */}
    </div>
    {/* <Link
      className="simple-link"
      onClick={() => {
        setSignupShow(true);
      }}
      to={void 0}
    >
      How to Join?
    </Link> */}
  </>
);

export const PresaleButton = ({ setSignupShow = () => {} }) => (
  <>
    <Link
      className="reg-btn br-radius mb-1"
      onClick={() => {
        setSignupShow(true);
      }}
      to={void 0}
    >
      Join Presale
    </Link>
    <br />
    {/* <Link
      className="simple-link"
      onClick={() => {
        setSignupShow(true);
      }}
      to={void 0}
    >
      How to Join?
    </Link> */}
  </>
);

export const PresaleButtons = ({ setSignupShow = () => {} }) => (
  <>
    <div className="btn-list justify-center">
      <li>
        <Link
          className="reg-btn br-radius mb-1 arrow-btn"
          onClick={() => {
            setSignupShow(true);
          }}
          to={void 0}
        >
          Join Presale <Image src={circleArrow} alt="Arrow" />
        </Link>
      </li>
      <li>
        {/* <Link
          className="reg-btn trans br-radius"
          onClick={() => {
            setSignupShow(true);
          }}
          to={void 0}
        >
          How to Join
        </Link> */}
      </li>
    </div>
  </>
);

export const PresalesButton = ({ setSignupShow = () => {} }) => (
  <>
    <Link
      className="reg-btn br-radius mb-1"
      onClick={() => {
        setSignupShow(true);
      }}
      to={void 0}
    >
      Join Presale
    </Link>
    <br />
    {/* <Link
      className="simple-link"
      onClick={() => {
        setSignupShow(true);
      }}
      to={void 0}
    >
      How to Join?
    </Link> */}
  </>
);

export const ReadMoreButton = ({ postLink }) => (
  <>
    <Link
      className="reg-btn br-radius mb-1"
      to={`/Blog/${postLink}`}
      style={{ borderRadius: "10px", padding: "10px" }}
    >
      Read More
    </Link>
  </>
);
