import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Form,
  Modal,
  Button,
  Image,
} from "react-bootstrap";
import icontether1 from "../Assets/img/Icons/icon-teather.png";
import coincard from "../Assets/img/Icons/icon-card.png";
import coinusdc from "../Assets/img/Icons/icon-usdc.png";
import coinbusd from "../Assets/img/Icons/icon-busd.png";
import coinbnb from "../Assets/img/Icons/icon-bnb.png";
import coincrypto from "../Assets/img/Icons/icon-crypto.png";
import tokenLogo from "../Assets/img/tokenLogo.png";
import coineth from "../Assets/img/Icons/icon-eth.png";
import coinusdt from "../Assets/img/Icons/icon-usdt.png";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import UserContest from "../ContextAPI/UserContest";
import {
  GraphTotal,
  getBonusTokenCountWithOutStage,
  getSumOfCurrencies,
  getTokenBonusCount,
  getTotalTokenOfwallet,
  getUserDetail,
} from "../Api";
import icon1 from "../Assets/img/Icons/svg3409.png";
import icon2 from "../Assets/img/Icons/coins (1).png";
import icon3 from "../Assets/img/Icons/coins (2).png";
import icon4 from "../Assets/img/Icons/coins.png";
import icon5 from "../Assets/img/Icons/Frame.png";
import icon6 from "../Assets/img/Icons/icon.png";
import icon7 from "../Assets/img/Icons/Layer_1 (1).png";
import icon8 from "../Assets/img/Icons/Layer_1.png";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import circleArrowwhite from "../Assets/img/Icons/arrow-right-white.png";
import Hero from "../Assets/img/Hero.png";
import { useAccount, useDisconnect } from "wagmi";

import Discord from "../Assets/img/Icons/icon-discord.png";
import {
  BannerVideo,
  CommasRegex,
  DiscordIcon,
  InstagramUrl,
  teleGramUrl,
  twitterIcon,
} from "../Constants";

// import { Promocode } from "../ContextAPI/type";
function Header({ setSignupShow, SignupShow }) {
  const {
    CurrentStage,
    TotalSaleInStage,
    OverAllSaleToken,
    SetPromocode,
    Promocode,
    SetUserDetail,
  } = useContext(UserContest);
  const iconsArray = [icon1, icon2, icon3, icon4, icon5, icon6, icon7, icon8];

  const [SelectedChain, setSelectedChain] = useState();
  const [BonusToken, setBonusToken] = useState();
  const navigate = useNavigate();
  const [totalToken, settotalToken] = useState(0);
  const [saleGraph, setsaleGraph] = useState();
  const [bonusWithoutStage, setBonusWithOutSale] = useState();
  // const address =?
  const { address, isConnecting, isDisconnected } = useAccount();

  const { disconnect } = useDisconnect();
  const valueToRemove = 118187802;
  const PercentageToRemove = 39952920;
  const DummyPercentage = 0.04;
  const [percentage, setPercentage] = useState();
  const Network = {
    mainNetwork: "mainNetwork",
    BUSD: "BUSD",
    USDT: "USDT",
    USDC: "USDC",
  };
  useEffect(() => {
    GraphTotal()
      .then((data) => {
        // console.log('GraphTotal',data.data.totalraised )
        setsaleGraph(data.data.totalraised);
      })
      .catch(() => {});
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (CurrentStage?.id)
      getTokenBonusCount(CurrentStage?.id)
        .then((data) => {
          // console.log('getTokenBonusCount',data.total_tokens_purchased)
          setBonusToken(parseInt(data.total_tokens_purchased) + 0);
        })
        .catch(() => {});
    getBonusTokenCountWithOutStage()
      .then((data) => {
        setBonusWithOutSale(parseInt(data.total_tokens_purchased) + 0);
      })
      .catch(() => {});
    getUserDetail()
      .then((detail) => {
        // console.log(detail ,'getUserDetail' )
        // navigate("/Dashboard");
        SetUserDetail(detail);
      })
      .catch(() => {
        if (localStorage.getItem("JwtToken")) {
          localStorage.clear();
        }
        // navigate('/')
      });

    let percentage =
      (parseInt(saleGraph) / parseInt(CurrentStage?.total_limit)) * 100;

    //  CurrentStage?.total_limit) - parseInt(TotalSaleInStage)
    let remaining = parseInt(
      parseInt(CurrentStage?.total_limit) -
        (parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken) >
        parseInt(CurrentStage?.total_limit)
          ? parseInt(CurrentStage?.total_limit)
          : parseInt(TotalSaleInStage ?? 0) + parseInt(BonusToken))
    );
    let sold = parseInt(parseInt(TotalSaleInStage) + parseInt(BonusToken));
    setPercentage(parseFloat((sold / (remaining + sold)) * 100).toFixed(2));

    // setPercentage( parseFloat( ( parseInt(TotalSaleInStage ?? 0) / parseInt(CurrentStage?.total_limit)) *100  ).toFixed(2)   ) ;
  }, [TotalSaleInStage, CurrentStage, saleGraph, BonusToken]);
  const DefaultChain = {
    ETH: "ETH",
    BNB: "BNB",
  };

  useEffect(() => {
    if (address)
      getTotalTokenOfwallet(address)
        .then((totlatoke) => {
          settotalToken(totlatoke.data ?? 0);
        })
        .catch(() => {});
  }, [address]);
  const [TotalRaised, setTotalRaised] = useState();

  useEffect(() => {
    let sum = 0;
    getSumOfCurrencies()
      .then((res) => {
        setTotalRaised(res.data.totalraised);
      })
      .catch(() => {});
  }, []);
  const bgVideoRef = useRef(null);
  const videoSource = BannerVideo; // Replace with the actual path to your video file

  useEffect(() => {
    // Set the playback rate to 0.5 for half speed (adjust as needed)
    if (bgVideoRef.current) {
      bgVideoRef.current.playbackRate = 0.5;
    }
  }, []);

  const [currentIconIndex, setCurrentIconIndex] = useState(0);

  useEffect(() => {
    // Set up an interval to change the icon every 2 seconds
    const intervalId = setInterval(() => {
      // Update the current icon index
      setCurrentIconIndex((prevIndex) => (prevIndex + 1) % iconsArray.length);
    }, 2000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run the effect only once on mount



  const [timeLeft, setTimeLeft] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    if(CurrentStage?.enddate ) {
    const endDate = new Date(`${CurrentStage?.enddate}:00`);

    const updateCountdown = () => {
      const now = new Date();
      const difference = endDate - now;

      if (difference > 0) {
        const days = String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, '0');
        const hours = String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0');
        const minutes = String(Math.floor((difference / (1000 * 60)) % 60)).padStart(2, '0');
        const seconds = String(Math.floor((difference / 1000) % 60)).padStart(2, '0');
        
        setTimeLeft({ days, hours, minutes, seconds });
      } else {
        setTimeLeft({ days: '00', hours: '00', minutes: '00', seconds: '00' });
      }
    };


    const timer = setInterval(updateCountdown, 1000);

    return () => clearInterval(timer); // Clean up the timer on component unmount
  }
  }, [CurrentStage]);







  return (
    <>
      <header className="container-fluid">
        <Container className="zindex">
          <Row>
            <Col xxl="12" xl="12" lg="12">
              <div className="hero-pnl">
                <div className="txt-pnl">
                  <div
                    onClick={() => {
                      setSignupShow(true);
                    }}
                  >
                    <h1>
                    Trade Over 10,000+ Crypto Assets With 
                      <span> Advanced AI </span>
                    </h1>
                    <h6 className="lightcolor font-weight-light text-capitalize">
                    Join the first crypto trading platform with native AI integration. Experience unparalleled risk management and profit opportunities with enterprise-grade trading tools. 

                    </h6>
                    <div className="spacer-20" />
                    <ul className="count-list">
      <li>
        <div className="count">{timeLeft.days}</div>
        <span>Days</span>
      </li>
      <li><p>:</p></li>
      <li>
        <div className="count">{timeLeft.hours}</div>
        <span>Hours</span>
      </li>
      <li><p>:</p></li>
      <li>
        <div className="count">{timeLeft.minutes}</div>
        <span>Minutes</span>
      </li>
      <li><p>:</p></li>
      <li>
        <div className="count">{timeLeft.seconds}</div>
        <span>Seconds</span>
      </li>
    </ul>
                  </div>
                  <div className="btn-list ">
                    <li>
                      <Link
                        className="reg-btn br-radius mb-1 arrow-btn"
                        onClick={() => {
                          setSignupShow(true);
                        }}
                        to={void 0}
                      >
                        <Image src={circleArrow} alt="Arrow" /> Join Presale
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="reg-btn trans br-radius"
                        onClick={() => {
                          setSignupShow(false);
                        }}
                     to="https://intel-markets.gitbook.io/intel-markets-whitepaper"
                        target="_blank"
                      >
                        <Image src={circleArrowwhite} alt="Arrow" /> White paper
                      </Link>
                    </li>
                  </div>
                </div>
                <div className="img-pnl">
                  <Image src={Hero} alt="Hero Image" />
                </div>
              </div>
            </Col>

            <Col
              xl="12"
              lg="12"
              md="12"
              style={
                !isDisconnected && !isConnecting ? { display: "none" } : {}
              }
              className="text-center"
            >
              <div className="spacer-50" />
              <div className="spacer-50" />
              <div
                className="stage-post"
                onClick={() => {
                  setSignupShow(true);
                }}
              >
                <div className="img-pnl">
                  <h5 className="mt-0 font-weight-medium lightcolor">
                    {CurrentStage?.level}
                  </h5>
                  <div className="custome-progress-bar-main">
                    <div className="custome-progress-bar" style={{ width: `${percentage}%` }}>
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                      <span />
                    </div>
                    <p>{percentage}%</p>
                  </div>

                  {/* <CircularProgressbar
                    value={percentage / 100}
                    maxValue={1}
                    text={`${percentage}%`}
                  /> */}
                  {/* <img src={sold} alt="Data" /> */}
                </div>
                <div className="txt-pnl mt-4">
                  <ul className="my-dotted-list w-100 m-0">
                    <li>
                      <p className="m-0 font-weight-normal ">Tokens Sold:</p>
                      <span></span>
                      <p className="m-0 font-weight-semibold lightgreycolor">
                        <img src={tokenLogo} alt="tether" />{" "}
                        {(
                          parseInt(TotalSaleInStage)  +  parseInt(BonusToken) )
                        
                          ?.toString()
                          .replace(CommasRegex, ",") ?? 0}
                      </p>
                    </li>
                    <li>
                      <p className="m-0 font-weight-normal ">
                        Tokens Remaining in Stage:
                      </p>
                      <span></span>
                      <p className="m-0 font-weight-semibold lightgreycolor">
                        <img src={tokenLogo} alt="tether" />{" "}
                        {parseFloat(
                          parseInt(
                            parseInt(CurrentStage?.total_limit) - (
                              parseInt(TotalSaleInStage ?? 0)
                              +
                              parseInt(BonusToken) )

                            // +
                            //   parseInt(BonusToken) >
                            // parseInt(CurrentStage?.total_limit)
                            //   ? parseInt(CurrentStage?.total_limit)
                            //   : parseInt(TotalSaleInStage ?? 0) +
                            //     parseInt(BonusToken)
                          )
                        )
                          ?.toString()
                          .replace(CommasRegex, ",")}
                        {/* <span>
                     {
                     parseFloat(parseInt(CurrentStage?.total_limit) -parseInt(TotalSaleInStage ?? 0)  )?.toString().replace(CommasRegex, ',')  

                     }
                        </span> */}
                      </p>
                    </li>
                    <li>
                      <p className="m-0 font-weight-normal ">
                        Current Round Price:
                      </p>
                      <span></span>
                      <p className="m-0 font-weight-semibold lightgreycolor">
                        <img src={icontether1} alt="tether" />{" "}
                        {CurrentStage?.price_of_token}
                      </p>
                    </li>

                    <li>
                      <p className="m-0 font-weight-normal">
                        Next Round Price:
                      </p>
                      <span></span>
                      <p className="m-0 font-weight-semibold lightgreycolor">
                        <img src={icontether1} alt="tether" />{" "}
                        {CurrentStage?.futuretokenprice}
                      </p>
                    </li>

                    <li className="w-100">
                      <p className="m-0 font-weight-normal">Total Raised:</p>
                      <span></span>
                      <p className="m-0 font-weight-semibold lightgreycolor">
                        <img src={icontether1} alt="tether" /> ${" "}
                        {(
                          (parseInt(TotalRaised  )  +  (  parseInt(BonusToken) *CurrentStage?.price_of_token ) 
                          ) 
                         
                        )
                          .toFixed(3)
                          ?.toString()
                          .replace(CommasRegex, ",") ?? 0}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="apply-code-pnl">
                <div className="apply-code-pnl-inner">
                  <Form>
                    <div
                      className="input-cntnr"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        value={Promocode}
                        onChange={(e) => {
                          let temp = e.target.value.split("ref=");
                          SetPromocode(
                            temp[1] ? temp[1].split("&")[0] : e.target.value
                          );
                        }}
                        autoComplete="off"
                        type="text"
                        placeholder="Enter Referral CODE here"
                      />
                    </div>
                  </Form>
                  <a
                    onClick={() => {
                      if (Promocode) setSignupShow(true);
                    }}
                    className="reg-btn br-radius"
                    href={void 0}
                  >
                    {/* <Image src={circleArrowwhite} alt="Arrow" />  */}
                    Apply Code
                  </a>
                </div>
              </div>
            </Col>

            <Col
              xl="12"
              lg="12"
              md="12"
              style={
                !isDisconnected && !isConnecting ? { display: "initial" } : {}
              }
              className="text-center displayNone"
            >
              <div className="spacer-50" />
              <div className="spacer-50" />
              <div className="stage-post">
                <div className="img-pnl">
                  <h5 className="mt-0 font-weight-medium lightcolor">
                    {CurrentStage?.level}
                  </h5>
                  {/* <CircularProgressbar
                    value={percentage / 100}
                    maxValue={1}
                    text={`${percentage}%`}
                  /> */}
                </div>
                <div className="txt-pnl mt-4">
                  <ul className="my-dotted-list w-100 m-0">
                    <li>
                      <p className="notranslate m-0 font-weight-normal">
                        Tokens Sold:
                      </p>
                      <span></span>
                      <p className="notranslate m-0 font-weight-semibold lightgreycolor">
                        <img src={tokenLogo} alt="tether" />{" "}
                        {(parseInt(TotalSaleInStage) +  parseInt(BonusToken) )
                          ?.toString()
                          .replace(CommasRegex, ",") ?? 0}
                      </p>
                    </li>
                    <li>
                      <p className="notranslate m-0 font-weight-normal">
                        Tokens remaining in stage:
                      </p>
                      <span></span>
                      <p className="notranslate m-0 font-weight-semibold lightgreycolor">
                        <img src={tokenLogo} alt="tether" />{" "}
                        {parseFloat(
                          parseInt(
                            parseInt(CurrentStage?.total_limit) -( 
                              parseInt(TotalSaleInStage ?? 0)  
                              +
                              parseInt(BonusToken) )
                            // +
                            //   parseInt(BonusToken) >
                            // parseInt(CurrentStage?.total_limit)
                            //   ? parseInt(CurrentStage?.total_limit)
                            //   : parseInt(TotalSaleInStage ?? 0) +
                            //     parseInt(BonusToken)
                          )
                        )
                          ?.toString()
                          .replace(CommasRegex, ",")}
                        {/* <span>
                      {
                          parseFloat(parseInt(CurrentStage?.total_limit) -parseInt(TotalSaleInStage ?? 0)  )?.toString().replace(CommasRegex, ',')  

                      }
                    </span> */}
                      </p>
                    </li>
                    <li>
                      <p className="notranslate m-0 font-weight-normal">
                        Current Round Price:
                      </p>
                      <span></span>
                      <p className="notranslate m-0 font-weight-semibold lightgreycolor">
                        <img src={icontether1} alt="tether" />{" "}
                        {CurrentStage?.price_of_token}{" "}
                      </p>
                    </li>

                    <li>
                      <p className="notranslate m-0 font-weight-normal">
                        Next Round Price:
                      </p>
                      <span></span>
                      <p className="notranslate m-0 font-weight-semibold lightgreycolor">
                        <img src={icontether1} alt="tether" />{" "}
                        {CurrentStage?.futuretokenprice}
                      </p>
                    </li>
                    <li className="w-100">
                      <p className="notranslate m-0 font-weight-normal">
                        Total Raised:
                      </p>
                      <span></span>
                      <p className="notranslate m-0 font-weight-semibold lightgreycolor">
                        <img src={icontether1} alt="tether" /> ${" "}
                        {(
                          (parseInt(TotalRaised  )  +  (  parseInt(BonusToken) *CurrentStage?.price_of_token ) 
                          ) 
                         
                        )
                          .toFixed(3)
                          ?.toString()
                          .replace(CommasRegex, ",") ?? 0}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="apply-code-pnl">
                <div className="flex-div">
                  <p className="m-0">
                    {" "}
                    <img src={iconcheck} alt="Icon Check" /> Promo Code{" "}
                    <span className="purple-color">“INTL15”</span> has been
                    applied! Your bonus is 15%
                  </p>
                  <Link to="/">
                    <img src={iconcross} alt="icon cross" />
                  </Link>
                </div>
              </div> */}
              <div className="apply-code-pnl">
                <div className="spacer-10" />
                <ul className="my-dotted-list full w-100">
                  <li>
                    <p className="notranslate m-0 font-weight-normal">
                      Connected Wallet
                    </p>
                    <span></span>
                    <p className="notranslate m-0 font-weight-semibold lightgreycolor address">
                      {address}
                    </p>
                  </li>
                  <li>
                    <p className="notranslate m-0 font-weight-normal">
                      Your balance:
                    </p>
                    <span></span>
                    <p className="notranslate m-0 mobile-view-flex font-weight-semibold lightgreycolor">
                      <b className="yellow-color mr-1">
                        {parseFloat(totalToken)?.toFixed(4) ?? 0 ?? 0}
                      </b>{" "}
                      Tokens{" "}
                      <a
                        className="yellow-color ml-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate("/walletTransactions/transaction");
                        }}
                        to={void 0}
                      >
                        Transactions history
                      </a>
                    </p>
                  </li>
                </ul>
                <div className="spacer-10" />
                <hr />
                <div className="text-center">
                  <div className="spacer-10" />
                  <Link
                    className="reg-btn  br-radius"
                    to={void 0}
                    onClick={async () => {
                      disconnect();
                      //  window.location.reload()
                    }}
                  >
                    Disconnect
                  </Link>
                </div>
              </div>
              <div className="spacer-30" />

              <ul className="coin-btn-list">
                <li>
                  <Link
                    to="/walletTransactions/ETH"
                    onClick={() => {
                      setSelectedChain("ETH");
                    }}
                    className="reg-btn br-radius"
                  >
                    <img src={coineth} alt="ETH" /> ETH
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/walletTransactions/${Network.USDT}`}
                    className="reg-btn br-radius"
                    onClick={() => {
                      setSelectedChain(Network.USDT);
                    }}
                  >
                    <img src={coinusdt} alt="USDT" /> USDT
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/walletTransactions/${Network.USDC}`}
                    className="reg-btn br-radius"
                    onClick={() => {
                      setSelectedChain(Network.USDC);
                    }}
                  >
                    <img src={coinusdc} alt="USDC" /> USDC
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/walletTransactions/${Network.BUSD}`}
                    className="reg-btn br-radius"
                    onClick={() => {
                      setSelectedChain(Network.BUSD);
                    }}
                  >
                    <img src={coinbusd} alt="BUSD" /> BUSD
                  </Link>
                </li>
              </ul>
              <ul className="coin-btn-list three">
                <li>
                  <Link
                    to="/walletTransactions/BNB"
                    className="reg-btn br-radius "
                    onClick={() => {
                      setSelectedChain("BNB");
                    }}
                  >
                    <img src={coinbnb} alt="BNB" /> BNB
                  </Link>
                </li>
                <li>
                  <Link
                    to={"/walletTransactions/OtherCurrency"}
                    className="reg-btn br-radius"
                  >
                    <img src={coincrypto} alt="Crypto" /> other crypto
                  </Link>
                </li>
                <li>
                  <Link
                    className="reg-btn br-radius"
                    to="https://www.moonpay.com/buy"
                    target="_blank"
                  >
                    <img src={coincard} alt="Card" /> card
                  </Link>
                </li>
              </ul>
              {/* <Link className="reg-btn br-radius trans" to="/HowToBuy">
                How to Join?
              </Link> */}
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
}
export default Header;
