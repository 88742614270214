import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import Logo from "../Assets/img/Logo/footer-logo.png";
import twitter from "../Assets/img/Icons/icon-twitter.png";
import telegram from "../Assets/img/Icons/icon-telegram.png";
import {
  DiscordIcon,
  InstagramUrl,
  WhitePaperLink,
  teleGramUrl,
  twitterIcon,
} from "../Constants";

function Footer({
  scrollToFeatured,
  RewardToFeatured,
  TokenToFeatured,
  FaqToFeatured,
  RoadToFeatured,
}) {
  return (
    <>
      <footer className="container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <div className="footer-top">
                  <Link className="footer-logo" to="/">
                    <img src={Logo} alt="Logo" />
                  </Link>
                  <ul className="footer-list">
                    <li>
                      <Link
                        to="/About"
                        className={`${useLocation().pathname == "/About" ? "active" : ""
                          }`}
                      >
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to="/Features"
                        className={`${useLocation().pathname == "/Features" ? "active" : ""
                          }`}>
                        Features
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="https://intel-markets.gitbook.io/intel-markets-whitepaper"
                        target="_blank"
                      >
                        whitepaper
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/FAQs"
                        className={`${useLocation().pathname == "/FAQs" ? "active" : ""
                          }`}
                      >FAQ</Link>
                    </li>
                    <li>
                      <Link to="/CommingSoon">Airdrop</Link>
                    </li>
                  </ul>
                  <ul className="footer-social-list">
                    <li>
                      <Link target="_blank" to={twitterIcon}>
                        <img src={twitter} alt="twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link target="_blank" to={teleGramUrl}>
                        <img src={telegram} alt="Telegram" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </Row>
        <Container>
          <Row>
            <Col xl="12" lg="12" className="footer-bottom">
              <p>© 2024 IntelMarkets. All rights reserved.</p>
              <ul className="bottom-list">
                <li>
                  <Link
                    to="/PrivacyPolicy"
                    className={`${useLocation().pathname == "/PrivacyPolicy" ? "active" : ""
                      }`}
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link
                    to="/TermsConditions"
                    className={`${useLocation().pathname == "/TermsConditions"
                      ? "active"
                      : ""
                      }`}
                  >
                    Terms and conditions
                  </Link>
                </li>
                <li>
                  <Link to="https://intelmarketspresale.com/INTELDOC.pdf" target="_blank">Registration</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}
export default Footer;
