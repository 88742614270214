import React, { useRef, useState } from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./styles/App.scss";
import { Route, Routes, useNavigate } from "react-router-dom";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import TransactionHistory from "./Pages/TransactionHistory";
import TermsConditions from "./Pages/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import CommingSoon from "./Pages/CommingSoon";
import Features from "./Pages/Features";
import DevUpdates from "./Pages/DevUpdates";
import DevDetails from "./Pages/DevDetails";
import About from "./Pages/About";
import Route2X from "./Pages/Route2X";

// import Faq from "./Pages/Faq";
// Admin Panel
import Dashboard from "./Pages/AdminPanel/Dashboard";
import AccountActivities from "./Pages/AdminPanel/AccountActivities";
import MyProfileDetails from "./Pages/AdminPanel/MyProfileDetails";
import MyToken from "./Pages/AdminPanel/MyToken";
import Transactions from "./Pages/AdminPanel/Transactions";
import PurchaseToken from "./Pages/AdminPanel/PurchaseToken";
import Referral from "./Pages/AdminPanel/Referral";
import WalletTransaction from "./Pages/AdminPanel/WalletTransactions";
import Giveaway from "./Pages/Giveaway";
import Faq from "./Pages/Faq";
import DevDetails2 from "./Pages/DevDetails2";
// Admin Panel

function NotFound() {
  return (
    <div className="pagenotfound">
      <h1>404 - Page Not Found</h1>
    </div>
  );
}

function Router() {
  const [showFooter, setShowFooter] = useState(true);
  const [showNavBar, setShowNavBar] = useState(true);
  const [SignupShow, setSignupShow] = useState(false);
  const [forgetPassword, setforgetPassword] = useState(false);
  const [loginShow, setloginShow] = useState(false);
  const isJwtTokenPresent =
    localStorage.getItem("JwtToken")?.length > 10 ? false : true;
  const [ctaModal, setctaModal] = useState(false);

  const Navigate = useNavigate();

  const featuredSectionRef = useRef(null);

  const scrollToFeatured = () => {
    // Navigate to the root path
    Navigate("/");

    // Scroll to the featured section after a short delay (adjust as needed)
    setTimeout(() => {
      if (featuredSectionRef.current) {
        featuredSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed
  };

  const RewardSectionRef = useRef(null);

  const RewardToFeatured = () => {
    Navigate("/");

    // Scroll to the featured section after a short delay (adjust as needed)
    setTimeout(() => {
      if (RewardSectionRef.current) {
        RewardSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed

    // Scroll to the featured section
  };
  const TokenSectionRef = useRef(null);

  const TokenToFeatured = () => {
    Navigate("/");

    // Scroll to the featured section after a short delay (adjust as needed)
    setTimeout(() => {
      if (TokenSectionRef.current) {
        TokenSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed

    // Scroll to the featured section
    // Scroll to the featured section
  };
  const RoadSectionRef = useRef(null);

  const RoadToFeatured = () => {
    Navigate("/");

    // Scroll to the featured section after a short delay (adjust as needed)
    setTimeout(() => {
      if (RoadSectionRef.current) {
        RoadSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 1000); // Adjust the delay as needed

    // Scroll to the featured section
  };

  const FAQeSectionRef = useRef(null);

  const FaqToFeatured = () => {
    // Scroll to the featured section
    FAQeSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {showNavBar && (
        <NavBar
          loginShow={loginShow}
          setctaModal={setSignupShow}
          ctaModal={SignupShow}
          setloginShow={setloginShow}
          scrollToFeatured={scrollToFeatured}
          RewardToFeatured={RewardToFeatured}
          TokenToFeatured={TokenToFeatured}
          setSignupShow={setctaModal}
          RoadToFeatured={RoadToFeatured}
          FaqToFeatured={FaqToFeatured}
          SignupShow={ctaModal}
          setShowFooter={setShowFooter}
          forgetPassword={forgetPassword}
        />
      )}
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Home
              featuredSectionRef={featuredSectionRef}
              RewardSectionRef={RewardSectionRef}
              TokenSectionRef={TokenSectionRef}
              RoadSectionRef={RoadSectionRef}
              FAQeSectionRef={FAQeSectionRef}
              setShowFooter={setShowFooter}
              setShowNavBar={setShowNavBar}
              setSignupShow={setSignupShow}
              SignupShow={SignupShow}
            />
          }
        />

        <Route
          exact
          path="/invite"
          element={
            <Home
              featuredSectionRef={featuredSectionRef}
              RewardSectionRef={RewardSectionRef}
              TokenSectionRef={TokenSectionRef}
              RoadSectionRef={RoadSectionRef}
              FAQeSectionRef={FAQeSectionRef}
              setShowFooter={setShowFooter}
              setShowNavBar={setShowNavBar}
              setSignupShow={setSignupShow}
              SignupShow={SignupShow}
            />
          }
        />
        <Route
          exact
          path="/forgetpassword/:email/:otp"
          element={
            <Home
              featuredSectionRef={featuredSectionRef}
              RewardSectionRef={RewardSectionRef}
              TokenSectionRef={TokenSectionRef}
              RoadSectionRef={RoadSectionRef}
              FAQeSectionRef={FAQeSectionRef}
              setShowNavBar={setShowNavBar}
              setShowFooter={setShowFooter}
              setSignupShow={setSignupShow}
              SignupShow={SignupShow}
            />
          }
        />
        <Route path="/TransactionHistory" element={<TransactionHistory />} />
        <Route path="/TermsConditions" element={<TermsConditions />} />
        <Route path="/FAQs" element={<Faq />} />
        <Route
          path="/Giveaway"
          element={<Giveaway setSignupShow={setSignupShow} />}
        />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy setctaModal={setctaModal} />} />
        <Route path="/CommingSoon" element={<CommingSoon   />} />
        <Route path="/Features" element={<Features setctaModal={setSignupShow} />} />
        <Route path="/DevUpdates" element={<DevUpdates  setctaModal={setSignupShow} />} />
   
        <Route path="/dev-updates-2" element={<DevDetails    setctaModal={setSignupShow} />} />
        <Route path="/dev-updates-1" element={<DevDetails2   setctaModal={setSignupShow}   />} />

        

        
        <Route path="/About" element={<About         setctaModal={setSignupShow} />} />
        <Route
          path="/Route2X"
          element={<Route2X setctaModal={setSignupShow} />}
        />
        <Route
          path="/walletTransactions/:currency"
          element={<WalletTransaction />}
        />
        {/* AdminPanel */}
        <Route
          path="/Dashboard"
          element={
            !isJwtTokenPresent ? (
              <Dashboard
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
              />
            ) : (
              <Home
                featuredSectionRef={featuredSectionRef}
                RewardSectionRef={RewardSectionRef}
                TokenSectionRef={TokenSectionRef}
                RoadSectionRef={RoadSectionRef}
                FAQeSectionRef={FAQeSectionRef}
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
                setSignupShow={setSignupShow}
                SignupShow={SignupShow}
              />
            )
          }
        />

        {/* AccountActivities Route */}
        <Route
          path="/AccountActivities"
          element={
            !isJwtTokenPresent ? (
              <AccountActivities
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
              />
            ) : (
              <Home
                featuredSectionRef={featuredSectionRef}
                RewardSectionRef={RewardSectionRef}
                TokenSectionRef={TokenSectionRef}
                RoadSectionRef={RoadSectionRef}
                FAQeSectionRef={FAQeSectionRef}
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
                setSignupShow={setSignupShow}
                SignupShow={SignupShow}
              />
            )
          }
        />

        {/* MyProfileDetails Route */}
        <Route
          path="/MyProfileDetails"
          element={
            !isJwtTokenPresent ? (
              <MyProfileDetails
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
              />
            ) : (
              <Home
                featuredSectionRef={featuredSectionRef}
                RewardSectionRef={RewardSectionRef}
                TokenSectionRef={TokenSectionRef}
                RoadSectionRef={RoadSectionRef}
                FAQeSectionRef={FAQeSectionRef}
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
                setSignupShow={setSignupShow}
                SignupShow={SignupShow}
              />
            )
          }
        />

        {/* MyToken Route */}
        <Route
          path="/MyToken"
          element={
            !isJwtTokenPresent ? (
              <MyToken
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
              />
            ) : (
              <Home
                featuredSectionRef={featuredSectionRef}
                RewardSectionRef={RewardSectionRef}
                TokenSectionRef={TokenSectionRef}
                RoadSectionRef={RoadSectionRef}
                FAQeSectionRef={FAQeSectionRef}
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
                setSignupShow={setSignupShow}
                SignupShow={SignupShow}
              />
            )
          }
        />

        {/* Transactions Route */}
        <Route
          path="/Transactions"
          element={
            !isJwtTokenPresent ? (
              <Transactions
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
              />
            ) : (
              <Home
                featuredSectionRef={featuredSectionRef}
                RewardSectionRef={RewardSectionRef}
                TokenSectionRef={TokenSectionRef}
                RoadSectionRef={RoadSectionRef}
                FAQeSectionRef={FAQeSectionRef}
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
                setSignupShow={setSignupShow}
                SignupShow={SignupShow}
              />
            )
          }
        />

        {/* PurchaseToken Route */}
        <Route
          path="/PurchaseToken"
          element={
            !isJwtTokenPresent ? (
              <PurchaseToken
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
              />
            ) : (
              <Home
                featuredSectionRef={featuredSectionRef}
                RewardSectionRef={RewardSectionRef}
                TokenSectionRef={TokenSectionRef}
                RoadSectionRef={RoadSectionRef}
                FAQeSectionRef={FAQeSectionRef}
                setShowFooter={setShowFooter}
                setShowNavBar={setShowNavBar}
                setSignupShow={setSignupShow}
                SignupShow={SignupShow}
              />
            )
          }
        />
        <Route
          path="/Referral"
          element={
            <Referral
              setShowFooter={setShowFooter}
              setShowNavBar={setShowNavBar}
            />
          }
        />

        {/* AdminPanel */}

        {/* 404 Page */}
        <Route path="*" element={<NotFound />} />
        {/* 404 Page */}
      </Routes>
      {showFooter && (
        <Footer
          scrollToFeatured={scrollToFeatured}
          RewardToFeatured={RewardToFeatured}
          TokenToFeatured={TokenToFeatured}
          setSignupShow={setSignupShow}
          RoadToFeatured={RoadToFeatured}
          FaqToFeatured={FaqToFeatured}
        />
      )}
    </>
  );
}

export default Router;
