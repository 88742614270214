import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Container, Col, Image } from "react-bootstrap";
import RouteHero from "../Assets/img/Home/RouteHero.png";
import circleArrow from "../Assets/img/Icons/icon-arrow-right.png";
import layermountain from "../Assets/img/mountain.png";
import grid from "../Assets/img/Bg/Grid-bg.png";
import gradient from "../Assets/img/Bg/center-circle.png";
function Route2X({setctaModal}) {
  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }


  useEffect(()=>{
    setctaModal(false)
    window.scrollTo(0,0);
  },[])

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);
  return (
    <>
      {/* Faqs Starts Here */}
      <section  onClick={()=>{
        setctaModal(true)
      }} className="container-fluid inner-page pb-0">
        <Container fluid className="Route-hero-pnl">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${layermountain})` }}
          />
          <Row>
            <Container>
              <Row>
                <Col
                  xxl={{ span: 6, order: 1, offset: 0 }}
                  xl={{ span: 6, order: 1, offset: 0 }}
                  lg={{ span: 6, order: 1, offset: 0 }}
                  md={{ span: 6, order: 1, offset: 0 }}
                  sm={{ span: 10, order: 2, offset: 1 }}
                  xs={{ span: 10, order: 2, offset: 1 }}
                  className="text-center scroll-anime left" 
                >
                  <Image src={RouteHero} alt="Hero" />
                </Col>
                <Col
                  xxl={{ span: 6, order: 2 }}
                  xl={{ span: 6, order: 2 }}
                  lg={{ span: 6, order: 2 }}
                  md={{ span: 6, order: 2 }}
                  sm={{ span: 12, order: 1 }}
                  xs={{ span: 12, order: 1 }}
                  className="scroll-anime right" 
                >
                  <div className="spacer-10" />
                  <h1 className="font-weight-bold heading-text whitecolor">
                    Introducing Route X21
                  </h1>
                  <div className="spacer-20" />
                  <h2 className="font-weight-bold">
                    <span>
                      The First Quantum Proof Protocol for a DeFi Exchange
                    </span>
                  </h2>
                  <div className="spacer-20" />
                  <p>
                    IBM has recently stated that it will have a
                    cryptographically relevant quantum computer by 2030. Other
                    stakeholders, such as nation-states and research institutes,
                    are even further ahead. With these developments, the
                    security of all major blockchains is at risk.
                  </p>
                  <div className="spacer-20 web-view" />
                  <Link
                    className="reg-btn mobile-view-margin br-radius arrow-btn"
                    style={{ minWidth: "180px" }}
                    href="#"
                  >
                    {" "}
                    <Image src={circleArrow} alt="Arrow" /> Buy Now
                  </Link>
                  <div className="spacer-50" />
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
        <Container fluid className="bottom-bg">
          <div
            className="bg-layer"
            style={{ backgroundImage: `url(${layermountain})` }}
          />
          <Row>
            <Container>
              <Row>
                <Col xl="12" lg="12">
                  <div className="routes-simple-text-pnl">
                    <Row>
                      <Col xl="6" lg="6" md="6">
                        <p>
                          The entire crypto industry is facing a $1 Trillion
                          challenge, which could question the entire survival of
                          the decentralized world. To address these challenges,
                          IntelMarkets (INTL) is introducing the Route X21
                          initiative to integrate the first quantum-proof
                          security protocol on our native blockchain.
                        </p>
                      </Col>
                      <Col xl="6" lg="6" md="6">
                        <p>
                          The research initiative is being seeded by a $1
                          Million grant from our partners in the MENA region.
                          This protocol development will focus on expanding
                          beyond techniques like the elliptic curve cryptography
                          for signatures and innovate new mechanisms to secure
                          our cutting-edge platform.
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col style={{ position: "relative" }} xl="12">
                  {/* <div
                    className="bg-layer"
                    style={{ backgroundImage: `url(${grid})`, opacity: '1' }}
                  /> */}
                  <div className="text-center">
                    <div className="spacer-50" />
                    <div className="spacer-50 web-view" />
                    <h1 className="whitecolor">
                      Integrating A<br /> Quantum-Proof Ledger
                    </h1>

                    <div className="spacer-50" />
                    <div className="spacer-50 web-view" />
                  </div>
                  <div className="text-posts-container-main">
                    <div className="text-posts-container">
                      <div className="text-post">
                        <div
                          className="bg-layer"
                          style={{
                            backgroundImage: `url(${gradient})`,
                            opacity: "1",
                          }}
                        />
                        <p>
                          The first part of the challenge is to develop a
                          Quantum-Proof Ledger (QPL) to ensure that the future
                          of blockchain systems is protected from the leaps in
                          Quantum technology. As observed with the exponential
                          growth in computing power, it is necessary to
                          proactively pursue the development of this initiative
                          to prevent essential security protocols from getting
                          compromised.
                        </p>
                        <span />
                        <span />
                      </div>

                      <div className="text-post">
                        <div
                          className="bg-layer"
                          style={{
                            backgroundImage: `url(${gradient})`,
                            opacity: "1",
                          }}
                        />
                        <p>
                          IntelMarkets (INTL)’s advanced quantum-proof
                          initiative will focus on overcoming polynomial
                          speed-up challenges and devise advanced security
                          techniques to protect the future of the industry and
                          to provide a safe decentralized experience to our
                          users.
                        </p>
                        <span />
                        <span />
                      </div>
                    </div>
                  </div>
                  <div className="spacer-50 web-view" />
                  <div className="text-center">
                    <h5 className="text-gradient font-weight-bold">
                      Keep up with our developmental updates to know more about
                      Route X21.
                    </h5>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
        </Container>
      </section>
      {/* Faqs Ends Here */}
    </>
  );
}
export default Route2X;
