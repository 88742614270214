import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
function Frequentlyquestion() {
  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);

  return (
    <>
      <Accordion>
        <Accordion.Item eventKey="0" className="scroll-anime left">
          <Accordion.Header>
            <span />
            What is IntelMarkets?
          </Accordion.Header>
          <Accordion.Body>
            IntelMarkets is a dual chain crypto exchange which provides traders
            with the opportunity to speculate on the future price movements of
            various digital assets without the constraints of expiration dates.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="scroll-anime left">
          <Accordion.Header>
            <span />
            How do I buy tokens?
          </Accordion.Header>
          <Accordion.Body>
            Buying INTL presale tokens is a straightforward process. Simply
            click the "Sign Up" button, proceed to register with your email
            address, and choose the preferred cryptocurrency for your payment.
            Alternatively, you can connect your wallet and pay using a wide
            variety of cryptocurrencies using various DeFi Wallets.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="scroll-anime left">
          <Accordion.Header>
            <span />
            What is Dual-Chain Functionality?
          </Accordion.Header>
          <Accordion.Body>
            IntelMarkets is a dual functionality exchange. This means the
            exchange supports and is run on both the Ethereum and Solana
            blockchains. This dual-chain architecture offers traders the
            flexibility to choose their preferred blockchain based on their
            specific requirements, preferences, and trading strategies.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="scroll-anime left">
          <Accordion.Header>
            <span />
            When will the token be listed on exchanges?
          </Accordion.Header>
          <Accordion.Body>
            INTL will be listed on both centralized exchanges (CEXs) and
            decentralized exchanges (DEXs) following the Launch of INTL. The
            exact list of exchanges will be announced as the Launch date
            approaches.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="scroll-anime left">
          <Accordion.Header>
            <span />
            What is the listing price?
          </Accordion.Header>
          <Accordion.Body>
            Minimum list price of INTL is $0.11 which is around an 11x (1100%
            increase) from the starting token presale price of $0.009.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className="scroll-anime left">
          <Accordion.Header>
            <span />
            What if i have more questions?
          </Accordion.Header>
          <Accordion.Body>
            If you have anymore questions, please reach out to
            info@intelmarketspresale.com
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
export default Frequentlyquestion;
