import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Row, Container, Col, Table, Pagination } from "react-bootstrap";

function TransactionHistory() {
  return (
    <>
      {/* Transaction History Starts Here */}
      <section className="inner-page container-fluid">
        <Row>
          <Container>
            <Row>
              <Col xl="12" lg="12" md="12" className="text-center">
                <h1 style={{ textTransform: 'capitalize' }}>This table shows all types and status of transactions</h1>
              </Col>
              <Col xl="12" lg="12" md="12">
                <div className="spacer-30"/>
                <div className="border-div-container">
                  <div className="flex-div-sm">
                    <p className="showing-e">
                      <b>
                        Showing <span>10</span> entries
                      </b>
                    </p>
                    <div className="search-pnl m-0">
                    <Form.Control type="email" placeholder="name@example.com" />
                    <button>
                      <i className="fa fa-search"></i>
                    </button>
                  </div>
                  </div>
                  <div className="spacer-20"/>
                  <div className="table-container">
                    <div className="table-container-inner">
                      <Table striped className="text-center">
                        <thead>
                          <tr>
                            <th>Transaction ID</th>
                            <th>Transaction Date</th>
                            <th>Tokens</th>
                            <th>Amount in Cryptocurrency</th>
                            <th>Amount in US Dollars</th>
                            <th>To Wallet Address</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>MAY 12, 2023</td>
                            <td>USDT</td>
                            <td>10,000.08</td>
                            <td>10,000.08</td>
                            <td className="color">0X232E...01C2</td>
                            <td className="greencolor">Success</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>DEC 12, 2023</td>
                            <td>BTC</td>
                            <td>0,5123</td>
                            <td>21,531.19</td>
                            <td className="color">
                              0xcf9f23aff3b85g59d3f4j8s9dh12854e825a2809
                            </td>
                            <td className="redcolor">Failed</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>MAY 12, 2023</td>
                            <td>USDT</td>
                            <td>10,000.08</td>
                            <td>10,000.08</td>
                            <td className="color">
                              0xcf9f23aff3b85g59d3f4j8s9dh12854e825a2809
                            </td>
                            <td className="greencolor">Success</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>MAY 12, 2023</td>
                            <td>USDT</td>
                            <td>10,000.08</td>
                            <td>10,000.08</td>
                            <td className="color">
                              0xcf9f23aff3b85g59d3f4j8s9dh12854e825a2809
                            </td>
                            <td className="redcolor">Rejected</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="spacer-10"/>
                  <div className="flex-div-sm">
                    <div className="text-left">
                      <p className="m-0">Showing 1 to 3 of 3 entries</p>
                    </div>
                    <div>
                      <Pagination>
                        <Pagination.Prev />
                        <Pagination.Item>{1}</Pagination.Item>
                        <Pagination.Item active>{2}</Pagination.Item>
                        <Pagination.Item>{3}</Pagination.Item>
                        <Pagination.Ellipsis />
                        <Pagination.Item>{10}</Pagination.Item>
                        <Pagination.Next />
                      </Pagination>
                    </div>
                  </div>
                </div>
                <div className="spacer-50"/>
              </Col>
            </Row>
          </Container>
        </Row>
      </section>
      {/* Transaction History Ends Here */}
    </>
  );
}
export default TransactionHistory;
